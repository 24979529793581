import * as actionTypes from './actionTypes';

export const uiToggleDrawer = () => {
  localStorage.setItem('ui.showDrawer', localStorage.getItem('ui.showDrawer') === 'true' ? false : true);
  return {
    type: actionTypes.UI_TOGGLE_DRAWER,
  }
}

export const uiLoad = () => {
  return {
    type: actionTypes.UI_LOAD,
    showDrawer: localStorage.getItem('ui.showDrawer') === 'true' ? true : false,
  }
}
