import React from 'react';
import { connect } from 'react-redux';

import UserLayout from './UserLayout/UserLayout';
import AdminLayout from './AdminLayout/AdminLayout';

const Layout = (props) => {
  let layout = null;
  if (props.isAdmin) {
    layout = (
      <AdminLayout {...props}>
        {props.children}
      </AdminLayout>
    )
  } else {
    layout = (
      <UserLayout {...props}>
        {props.children}
      </UserLayout>
    )
  }
  return (
    <>
      {layout}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.role === 'admin',
  }
}

export default connect(mapStateToProps)(Layout);
