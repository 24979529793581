import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const EditUser = (props) => {
  const classes = useStyles();

  const [ user, setUser ] = useState({ role: '' });
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isNew, setIsNew ] = useState(true);
  const [ isCreatedSuccess, setIsCreatedSuccess ] = useState(false);
  const [ isErrorOccurred, setIsErrorOccurred ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('Error ocured while creating user');

  useEffect(() => {
    if (props.match.params.id) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }
      axios.get(`/users/${props.match.params.id}`, config)
        .then((response) => {
          setIsNew(false);
          setIsLoading(false);
          setUser(response.data);
        })
        .catch((error) => {
          props.history.goBack();
        })
    } else {
      setIsNew(true);
      setIsLoading(false);
      setUser({
        role: 'user',
      });
    }
  }, []);

  const onValueChangedHandler = (event, key) => {
    setUser({
      ...user,
      [key]: event.target.value,
    });
  }

  const onSaveClickedHandler = () => {
    if (user.password !== user.password2) {
      setIsErrorOccurred(true);
      setErrorMessage('Passwords missmatch');
      return;
    }

    setIsLoading(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    const data = {
      name: user.name,
      email: user.email,
      password: user.password,
      role: user.role,
    }
    if (isNew) {
      axios.post('/users', data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsErrorOccurred(true);
          setErrorMessage('Error ocured while creating user');
        });
    } else {
      data.id = props.match.params.id;
      axios.put(`/users/${data.id}`, data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsErrorOccurred(true);
          setErrorMessage('Error ocured while saveing user');
        });
    }
  }

  const onFinishCreateingUserHandler = () => {
    props.history.push('/users');
  }

  let content = (
    <>
      <form>
        <FormControl className={classes.formControl}>
          <TextField label="Name" value={user.name} onChange={(event) => onValueChangedHandler(event, 'name')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Email" value={user.email} type="email" onChange={(event) => onValueChangedHandler(event, 'email')} disabled={!isNew} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Password" value={user.password} type="password" onChange={(event) => onValueChangedHandler(event, 'password')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Password" value={user.password2} type="password" onChange={(event) => onValueChangedHandler(event, 'password2')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <InputLabel>Role</InputLabel>
          <Select value={user.role} onChange={(event) => onValueChangedHandler(event, 'role')}>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </form>
      <br />
      <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClickedHandler}>Save</Button>
    </>
  );

  if (isLoading) {
    content = <CircularProgress color="secondary" size={100} />;
  }

  return (
    <Layout title="Users">
      <CustomCard>
        {content}
      </CustomCard>

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="User saved successfully"
        onDialogClosed={onFinishCreateingUserHandler} />
      <Alert
        show={isErrorOccurred}
        title="Error"
        message={errorMessage}
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(EditUser);
