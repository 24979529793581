import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import SplitButton from '../../components/SplitButton/SplitButton';
import DataTable from '../../components/DataTable/DataTable';
import ConfirmAlert from '../../components/ConfirmAlert/ConfirmAlert';
import axios from '../../axios-main';

const Users = (props) => {
  const [ users, setUsers ] = useState([]);
  const [ confirmPayload, setConfirmPayload ] = useState(null);

  const columns = [
    {
      id: 'email',
      title: 'Email',
    },
    {
      id: 'name',
      title: 'Name',
    },
    {
      id: 'role',
      title: 'Role',
    },
    {
      id: 'actions',
      title: '',
    },
  ];

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    axios.get('/users', config)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteUser = (id) => {
    setConfirmPayload(null);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.delete(`/users/${id}`, config)
      .then((response) => {
        setUsers(users.filter(el => el.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const createUserHandler = () => {
    props.history.push('/users/create');
  }

  const onEditClickedHandler = (id) => {
    props.history.push(`/users/${id}`);
  }

  const onDeleteClickedHandler = (id) => {
    setConfirmPayload(id);
  }

  const data = users.filter(el => el.id !== props.user.id).map((el) => {
    return {
      email: el.email,
      name: el.name,
      role: el.role === 'admin' ? 'Admin' : 'User',
      actions: (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => onEditClickedHandler(el.id)}
          >
            Edit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteClickedHandler(el.id)}
          >
            Delete
          </Button>
        </>
      ),
    }
  });

  return (
    <Layout title="Users">
      <CustomCard>
        <Button variant="contained" color="primary" onClick={createUserHandler}>Create User</Button>
      </CustomCard>
      <br />
      <CustomCard title="Users">
        <DataTable
          columns={columns}
          data={data} />
      </CustomCard>

      <ConfirmAlert
        show={confirmPayload}
        payload={confirmPayload}
        title="Delete User"
        message="Are you sure you want to delete user"
        onConfirmed={(payload) => deleteUser(payload)}
        onDiscarded={() => setConfirmPayload(null)}
        />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Users);
