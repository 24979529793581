import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import BarChart from '../../components/Charts/BarChart/BarChart';
import LineChart from '../../components/Charts/LineChart/LineChart';
import axios from '../../axios-main';

const useStyles = makeStyles({
  gridContainer: {
    padding: 20,
  },
  formControl: {
    width: 200,
  },
});

const Dashboard = (props) => {
  const classes = useStyles();

  const [finishedByUser, setFinishedByUser] = useState([]);
  const [finishedByDate, setFinishedByDate] = useState([]);

  const [from, setFrom] = useState('2021-01-01');
  const [to, setTo] = useState(dateFormat(new Date(), 'yyyy-mm-dd'));

  const getStatistics = (from = '2021-01-01', to = dateFormat(new Date(), 'yyyy-mm-dd')) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    axios.get(`/statistics?from=${from}&to=${to}`, config)
      .then((response) => {
        setFinishedByUser(response.data.finished_projects_by_user.map((el) => {
          return {
            label: el.name,
            value: el.count,
          }
        }));
        setFinishedByDate(response.data.finished_projects_by_date.map((el) => {
          return {
            label: dateFormat(new Date(el.date), 'mmmm dS'),
            value: el.count,
          }
        }));
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    console.log(from, to);
    getStatistics(from, to);
  }, [from, to]);

  return (
    <Layout title="Dashboard">
      <CustomCard title="Statistics">
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <CustomCard>
              <TextField
                className={classes.formControl}
                label="From"
                value={from}
                type="date"
                InputLabelProps={{shrink: true}}
                onChange={(event) => setFrom(event.target.value)} />

              &nbsp;
              &nbsp;
              &nbsp;

              <TextField
                className={classes.formControl}
                label="To"
                value={to}
                type="date"
                InputLabelProps={{shrink: true}}
                onChange={(event) => setTo(event.target.value)} />
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard title="Finished by User">
              <div style={{width: '100%', height: 300}}>
                <BarChart data={finishedByUser} />
              </div>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard title="Finished by Date">
              <div style={{width: '100%', height: 300}}>
                <LineChart data={finishedByDate} />
              </div>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard>
            </CustomCard>
          </Grid>
        </Grid>
      </CustomCard>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(Dashboard);
