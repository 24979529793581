import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import UpdateIcon from '@material-ui/icons/Update';
import dateFormat from 'dateformat';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import SplitButton from '../../components/SplitButton/SplitButton';
import DataTable from '../../components/DataTable/DataTable';
import ConfirmAlert from '../../components/ConfirmAlert/ConfirmAlert';
import axios from '../../axios-main';
import * as actions from '../../store/actions';
import { projectStatusDisplay, projectPriorityDisplay } from '../../data/helpers';

const Projects = (props) => {
  const [ projects, setProjects ] = useState([]);
  const [ confirmPayload, setConfirmPayload ] = useState(null);

  const [ currentPage, setCurrentPage ] = useState(1);
  const [ lastPage, setLastPage ] = useState(1);

  const [ typingTimeout, setTypingTimeout ] = useState(null);
  const [ search, setSearch ] = useState(null);

  const [ deleteModalId, setDeleteModalId ] = useState(null);
  const [ deleteModalName, setDeleteModalName ] = useState(null);

  useEffect(() => {
    loadPage(1);
  }, [search]);

  const loadPage = (page) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    let url = `/projects?page=${page}`;
    if (props.my_projects) {
      url = `/user_projects?page=${page}`;
    }
    if (search && search !== '') {
      url += `&search=${search}`;
    }
    axios.get(url, config)
      .then((response) => {
        setCurrentPage(response.data.current_page);
        setLastPage(response.data.last_page);
        setProjects(response.data.data.filter(el => !el.deleted));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const createProjectHandler = () => {
    props.history.push('/projects/create');
  }

  const createProjectsFromFilesHandler = () => {
    props.history.push('/projects/create_from_files');
  }

  const columns = [
    {
      id: 'project_name',
      title: 'Project Name',
    },
    {
      id: 'gf',
      title: 'GF',
    },
    {
      id: 'customer',
      title: 'Customer',
    },
    {
      id: 'priority',
      title: 'Priority',
    },
    {
      id: 'borrower_name',
      title: 'Borrower',
    },
    {
      id: 'address',
      title: 'Address',
    },
    {
      id: 'county',
      title: 'County',
    },
    {
      id: 'state',
      title: 'State',
    },
    {
      id: 'search_type',
      title: 'Search Type',
    },
    {
      id: 'date_ordered',
      title: 'Date Ordered',
    },
    {
      id: 'days_open',
      title: 'Days Open',
    },
    {
      id: 'assigned_to',
      title: 'Assigned To',
    },
    {
      id: 'status',
      title: 'Status',
    },
    {
      id: 'actions',
      title: '',
      minWidth: 150,
    },
  ];

  const deleteProject = (id) => {
    setDeleteModalId(null);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    axios.delete(`/projects/${id}`, config)
      .then((response) => {
        loadPage(currentPage);
      })
      .catch((err) => console.log(err));
  }

  const onEditClickedHandler = (id) => {
    props.history.push(`/projects/${id}`);
  }

  const onCopyClickedHandler = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    
    const data = {
    }

    axios.post(`/projects/${id}/copy`, data, config)
      .then((response) => {
        console.log(response.data);
        props.history.push(`/projects/${response.data.id}/process`);
      })
      .catch((err) => console.log(err));
  }

  const onDeleteClickedHandler = (id, name) => {
    setDeleteModalId(id);
    setDeleteModalName(name);
  }

  const onInvoiceClickedHandler = (id) => {
    props.history.push(`/projects/${id}/invoices`);
  }

  const onUpdateClickedHandler = (id) => {
    props.history.push(`/projects/${id}/updates`);
  }

  const onProccessClickedHandler = (id) => {
    props.resetProject();
    props.history.push(`/projects/${id}/process`);
  }

  const onPageChangedHandler = (page) => {
    loadPage(page);
  }

  const onSearchChangedHandler = (event) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      setTypingTimeout(null);
      setSearch(event.target.value);
    }, 500));
  }

  const data = projects.map((el) => {

    let daysOpen = null;

    if (el.date_ordered) {
      const diffTime = el.status === 3 ? new Date(el.updated_at) - new Date(el.date_ordered) : new Date() - new Date(el.date_ordered);
      daysOpen = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    }

    return {
      project_name: el.project_name,
      gf: el.gf,
      priority: projectPriorityDisplay(el.priority),
      state: el.state,
      borrower_name: el.borrower_name,
      address: el.address,
      assigned_to: el.assigned_to.name,
      status: projectStatusDisplay(el.status),
      customer: el.customer,
      county: el.county,
      search_type: el.search_type,
      date_ordered: el.date_ordered ? dateFormat(new Date(el.date_ordered), 'mm/dd/yyyy') : null,
      days_open: daysOpen,
      actions: (
        <>
          <Tooltip title="Process">
            <IconButton
              color="primary"
              onClick={() => onProccessClickedHandler(el.id)}
            >
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
          {
            props.isAdmin && (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => onEditClickedHandler(el.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Invoice">
                  <IconButton
                    color="primary"
                    onClick={() => onInvoiceClickedHandler(el.id)}
                  >
                    <AttachMoneyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy">
                  <IconButton
                    color="primary"
                    onClick={() => onCopyClickedHandler(el.id)}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Update">
                  <IconButton
                    color="primary"
                    onClick={() => onUpdateClickedHandler(el.id)}
                  >
                    <UpdateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    color="secondary"
                    onClick={() => onDeleteClickedHandler(el.id, el.project_name)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )
          }
        </>
      ),
    }
  });

  return (
    <Layout title="Projects">
      <ConfirmAlert
        show={deleteModalId}
        payload={deleteModalId}
        title="Delete Project"
        message={'Are you sure you wnat to delete project ' + deleteModalName}
        onDiscarded={() => setDeleteModalId(null)}
        onConfirmed={(id) => deleteProject(id)} />

      {
        !props.my_projects && props.isAdmin && (
          <>
            <CustomCard>
              <SplitButton options={[
                { title: 'Create Project', callback: createProjectHandler },
                { title: 'Create Project From Files', callback: createProjectsFromFilesHandler },
              ]} />
            </CustomCard>
            <br />
          </>
        )
      }
      <CustomCard title="Projects">
        <TextField label="Search" onChange={onSearchChangedHandler} />
        <br />
        <br />

        <DataTable
          columns={columns}
          data={data}
          pagination={{
            currentPage: currentPage,
            lastPage: lastPage,
          }}
          onPageChanged={onPageChangedHandler}
          size="small" />
      </CustomCard>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isAdmin: state.auth.user.role === 'admin',
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetProject: () => dispatch(actions.projectReset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
