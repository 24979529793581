import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 300,
    maxWidth: 400,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const Judgement = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.judgements.length === 0) {
      props.addJudgement({
        judgement_exists: false,
        number_of_documents: 0,
        copies_attached: false,
        lines_attached: false,
        plat: '',
        ccrs: '',
        ucc: '',
        other_1: '',
        other_2: '',
        other_3: '',
        notes: '',
      });
    }
  }, []);

  const updateFieldHandler = (event, index, field) => {
    let value = event.target.value
    if (field === 'judgement_exists' || field === 'copies_attached' || field === 'lines_attached') {
      value = event.target.checked;

      if (field === 'judgement_exists') {
          props.updateJudgement(index, 'copies_attached', false);
          props.updateJudgement(index, 'lines_attached', false);
          props.updateJudgement(index, 'number_of_documents', 0);
      }
    }

    props.updateJudgement(index, field, value);
  }

  const judgements = props.judgements.map((el, index) => {
    let judgementFields = null;
    if (el.judgement_exists) {
      judgementFields = (
        <>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <FormControlLabel
                value="copies_attached"
                control={<Switch color="primary" checked={el.copies_attached} onChange={(event) => updateFieldHandler(event, index, 'copies_attached')} />}
                label="Copies attached"
                labelPlacement="right" />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="lines_attached"
                control={<Switch color="primary" checked={el.lines_attached} onChange={(event) => updateFieldHandler(event, index, 'lines_attached')} />}
                label="Add'l lines attached"
                labelPlacement="right" />
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={6}>
            <FormControlLabel
              value="judgement_exists"
              control={<Switch color="primary" checked={el.judgement_exists} onChange={(event) => updateFieldHandler(event, index, 'judgement_exists')} />}
              label="Judgment"
              labelPlacement="right" />
          </Grid>
          <Grid item xs={6}>
            { el.judgement_exists ?
                <TextField
                  className={classes.formControl}
                  label="Number of Documents"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">#</InputAdornment>,
                  }}
                  value={el.number_of_documents}
                  onChange={(event) => updateFieldHandler(event, index, 'number_of_documents')} />
            : null }
          </Grid>
        </Grid>
        {judgementFields}
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="PLAT"
            value={el.plat}
            onChange={(event) => updateFieldHandler(event, index, 'plat')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="CCRS"
            value={el.ccrs}
            onChange={(event) => updateFieldHandler(event, index, 'ccrs')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="UCC"
            value={el.ucc}
            onChange={(event) => updateFieldHandler(event, index, 'ucc')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="Other"
            value={el.other_1}
            onChange={(event) => updateFieldHandler(event, index, 'other_1')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="Other"
            value={el.other_2}
            onChange={(event) => updateFieldHandler(event, index, 'other_2')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="Other"
            value={el.other_3}
            onChange={(event) => updateFieldHandler(event, index, 'other_3')} />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            className={classes.formControl}
            label="Notes"
            value={el.note}
            onChange={(event) => updateFieldHandler(event, index, 'note')} />
        </Grid>
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          {judgements}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    judgements: state.project.judgements,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addJudgement: (judgement) => dispatch(actions.projectArrayAdd('judgements', judgement)),
    updateJudgement: (index, field, value) => dispatch(actions.projectArrayUpdate('judgements', index, field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Judgement);
