import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import axios from '../../../axios-main';
import * as actions from '../../../store/actions';
import { states, counties } from '../../../data/data';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 700,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const priorities = [
  {
    value: 3,
    text: 'Normal',
  },
  {
    value: 2,
    text: 'High',
  },
  {
    value: 1,
    text: 'Top',
  },
];

const showPriority = (isEditable, value, onChangeHandler, classes) => {
  if (!isEditable) {
    const priority = value ? priorities.find(el => el.value === value).text : null;
    return (
      <>
        Priority: {priority}
      </>
    );
  }

  const priorityOptions = priorities.map((el) => {
    return (
      <MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>
    );
  });

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Priority</InputLabel>
      <Select value={value} onChange={(event) => onChangeHandler(event, 'priority')}>
        {priorityOptions}
      </Select>
    </FormControl>
  );
}

const Project = (props) => {
  const classes = useStyles();

  const [countyOptions, setCountyOptions] = useState([]);
  const [searchTypeOptions, setSearchTypeOptions] = useState([]);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const {state} = props.project;

  useEffect(() => {
    if (!props.project.state) {
      props.updateProject('state', 'TX');
      setIsStateChanged(true);
    }

    if (props.taxes.length === 0) {
      props.addTax({
        parcel_no: '',
        legal_description: '',
        land_value: '0',
        improvements: '0',
        other_value: '0',
        total_assessed: '0',
        paid_thru_1_year: '',
        paid_thru_1_value: 0,
        paid_thru_1_co: false,
        paid_thru_1_cty: false,
        paid_thru_2_year: '',
        paid_thru_2_value: 0,
        paid_thru_2_co: false,
        paid_thru_2_cty: false,
        due_1_year: '',
        due_1_value: 0,
        due_1_co: false,
        due_1_cty: false,
        due_2_year: '',
        due_2_value: 0,
        due_2_co: false,
        due_2_cty: false,
        chain: false,
        public_road: true,
        mobile_home: 0,
        note: '',
      });
    }

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    axios.get('/search_type_options', config)
      .then((response) => {
        setSearchTypeOptions(response.data);
      })
      .catch((error) => {
      })
  }, []);

  useEffect(() => {
    const newCountyOptions = counties.filter(county => props.project.state === county.state).map(el => el.name);
    setCountyOptions(newCountyOptions);
      //setIsStateChanged(false);
  }, [state]);

  const updateFieldHandler = (event, field, newValue) => {
    let value = event.target.value;
    if (newValue) {
      value = newValue;
    }

    props.updateProject(field, value);
  }

  const updateTaxFieldHandler = (event, index, field) => {
    props.updateTax(index, field, event.target.value);
  }

  const reportProblemHandler = () => {
    props.updateProject('internal_note', ' ');
  }

  const reportProblem = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    const data = {
      internal_note: props.project.internal_note,
    }

    axios.post(`/projects/${props.project.id}/report`, data, config)
      .then((response) => {
        props.history.goBack();
      })
      .catch((err) => console.log(err));
  }

  /*
  const stateOptions = states.map((el) => {
    return (
      <MenuItem key={el.abbreviation} value={el.abbreviation}>{el.abbreviation + ' - ' + el.name}</MenuItem>
    );
  });
  */
  const stateOptions = states.map(el => el.abbreviation + ' - ' + el.name);

  let internalNoteUI = (
    <Grid item xs={12}>
      <Button variant="contained" color="primary" onClick={reportProblemHandler}>Report problem</Button>
    </Grid>
  );
  if (props.project.internal_note) {
    internalNoteUI = (
      <>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Internal note"
            multiline
            rows={4}
            value={props.project.internal_note}
            onChange={(event) => updateFieldHandler(event, 'internal_note')} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={reportProblem}>Report problem</Button>
        </Grid>
      </>
    );
  }

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Project Name"
              value={props.project.project_name}
              onChange={(event) => updateFieldHandler(event, 'project_name')} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="GF"
              value={props.project.gf}
              onChange={(event) => updateFieldHandler(event, 'gf')} />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Borrower Name"
              value={props.project.borrower_name}
              onChange={(event) => updateFieldHandler(event, 'borrower_name')} />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="state"
              className={classes.formControl}
              options={stateOptions}
              value={props.project.state + ' - ' + states.find(el => el.abbreviation === props.project.state)?.name}
              onChange={(event, newValue) => updateFieldHandler(event, 'state', newValue.split(' ')[0])}
              renderInput={(params) => <TextField {...params} label="State" />}
              />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="county"
              className={classes.formControl}
              options={countyOptions}
              value={props.project.county}
              onChange={(event, newValue) => updateFieldHandler(event, 'county', newValue)}
              renderInput={(params) => <TextField {...params} label="County" />}
              />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Address"
              value={props.project.address}
              onChange={(event) => updateFieldHandler(event, 'address')} />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete id="search_type"
              className={classes.formControl}
              options={searchTypeOptions.map(el => el.text)}
              value={props.project.search_type}
              onChange={(event, newValue) => updateFieldHandler(event, 'search_type', newValue)}
              renderInput={(params) => <TextField {...params} label="Search Type" />}
              />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            { showPriority(false, props.project.priority, updateFieldHandler, classes) }
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />
          </Grid>
          {internalNoteUI}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    project: state.project.project,
    taxes: state.project.taxes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateProject: (field, value) => dispatch(actions.projectFieldUpdate(field, value)),
    addTax: (tax) => dispatch(actions.projectArrayAdd('taxes', tax)),
    updateTax: (index, field, value) => dispatch(actions.projectArrayUpdate('taxes', index, field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Project));
