import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
}));

const DataTable = (props) => {
  const header = props.columns.map((el) => {
    let align = 'left';
    if (el.align) {
      align = el.align
    }
    return (
      <TableCell key={el.id} align={align} minWidth={el.minWidth} style={{minWidth: el.minWidth}}>
        {el.title}
      </TableCell>
    );
  });

  const data = props.data.map((row, index) => {
    const cells = [];
    props.columns.forEach((el) => {
      let align = 'left';
      if (el.align) {
        align = el.align;
      }

      cells.push(
        <TableCell key={el.id} align={align}>
          {el.callback ? el.callback(row[el.id]) : row[el.id]}
        </TableCell>
      );
    });

    return (
      <TableRow key={index}>
        {cells}
      </TableRow>
    );
  });

  let pagination = null;
  if (props.pagination) {
    pagination = (
      <Pagination
        page={props.pagination.currentPage}
        count={props.pagination.lastPage}
        color="primary"
        onChange={(event, value) => props.onPageChanged(value)} />
    );
  }

  return (
    <Paper>
      <TableContainer>
        <Table size={props.size}>
          <TableHead>
            <TableRow>
              {header}
            </TableRow>
          </TableHead>
          <TableBody>
            {data}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {pagination}
      <br />
    </Paper>
  );
}

export default DataTable;
