export const updateObject = (obj, updatedProps) => {
  return {
    ...obj,
    ...updatedProps,
  }
}

export const updateArray = (arr, index, value) => {
  const newArr = [ ...arr ];
  newArr[index] = value;
  return newArr;
}
