import React from 'react';
import {
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';

const CustomCard = (props) => {
  let title = null;
  if (props.title) {
    title = (
      <>
        <Typography variant="h5" component="h5">
          {props.title}
        </Typography>
        <br />
      </>
    );
  }

  return (
    <Card style={{...props.style}}>
      <CardContent>
        {title}
        {props.children}
      </CardContent>
    </Card>
  );
}

export default CustomCard;
