import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const priorities = [
  {
    value: 3,
    text: 'Normal',
  },
  {
    value: 2,
    text: 'High',
  },
  {
    value: 1,
    text: 'Top',
  },
];

const EditProject = (props) => {
  const classes = useStyles();

  const [ project, setProject ] = useState();
  const [ employees, setEmployees ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isNew, setIsNew ] = useState(true);
  const [ isCreatedSuccess, setIsCreatedSuccess ] = useState(false);
  const [ isErrorOccurred, setIsErrorOccurred ] = useState(false);

  const [ customerOptions, setCustomerOptions ] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + props.token,
          }
        }
        const employees = (await axios.get('/users', config)).data;
        console.log(employees);
        setEmployees(employees);

        const customers = (await axios.get('/clients', config)).data;
        setCustomerOptions(customers.map(el => ({ text: el.name })));

        if (props.match.params.id) {
          const project = (await axios.get(`/projects/${props.match.params.id}`, config)).data;
          setProject({
            ...project,
            created_by: project.created_by.id,
            assigned_to: project.assigned_to.id,
          });
          setIsNew(false);
          setIsLoading(false);
        } else {
          setProject({
            priority: 3,
            assigned_to: props.user.id,
            date_ordered: dateFormat(new Date(), 'yyyy-mm-dd'),
          });
          setIsNew(true);
          setIsLoading(false);
        }
      } catch (error) {
        props.history.goBack();
      }
    })();
  }, []);

  const onValueChangedHandler = (event, key) => {
    setProject({
      ...project,
      [key]: event.target.value,
    });
  }

  const onSaveClickedHandler = () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    const data = {
      project_name: project.project_name,
      gf: project.gf,
      priority: project.priority,
      assigned_to: project.assigned_to,
      state: project.state && project.state !== '' ? project.state : 'TX',
      customer: project.customer,
      date_ordered: project.date_ordered,
    }
    if (isNew) {
      axios.post('/projects', data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsErrorOccurred(true);
        });
    } else {
      data['id'] = project.id;
      axios.put(`/projects/${project.id}`, data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsErrorOccurred(true);
        });
    }
  }

  const onFinishCreateingProjectHandler = () => {
    props.history.push('/projects');
  }

  const priorityOptions = priorities.map((el) => {
    return (
      <MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>
    );
  });

  const employeeOptions = employees.map((el) => {
    return (
      <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
    );
  });

  let content = <CircularProgress color="secondary" size={100} />;
  if (!isLoading) {
    content = (
      <>
        <form>
          <FormControl className={classes.formControl}>
            <TextField label="Title" value={project.project_name} onChange={(event) => onValueChangedHandler(event, 'project_name')} />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField label="GF" value={project.gf} onChange={(event) => onValueChangedHandler(event, 'gf')} />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel>Priority</InputLabel>
            <Select value={project.priority} onChange={(event) => onValueChangedHandler(event, 'priority')}>
              {priorityOptions}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel>Assigned To</InputLabel>
            <Select value={project.assigned_to} onChange={(event) => onValueChangedHandler(event, 'assigned_to')}>
              {employeeOptions}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel>Customer</InputLabel>
            <Select value={project.customer} onChange={(event) => onValueChangedHandler(event, 'customer')}>
              {customerOptions.map(el => <MenuItem key={el.id} value={el.text}>{el.text}</MenuItem>)}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              label="Date Ordered"
              InputLabelProps={{shrink: true}}
              value={project.date_ordered ? project.date_ordered.split(' ')[0] : null}
              onChange={(event) => onValueChangedHandler(event, 'date_ordered')} />
          </FormControl>
        </form>
        <br />
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClickedHandler}>Save</Button>
      </>
    );
  }

  return (
    <Layout title="Projects">
      <CustomCard>
        {content}
      </CustomCard>

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="Project saved successfully"
        onDialogClosed={onFinishCreateingProjectHandler} />
      <Alert
        show={isErrorOccurred}
        title="Error"
        message="Error ocured while saveing project"
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(EditProject);
