import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import dateFormat from 'dateformat';

const styles = StyleSheet.create({
  page: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingHorizontal: 25,
    fontSize: 11
  },
  table: {
    width: '100%',
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 12
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
    paddingVertical: 3,
    paddingHorizontal: 10,
  },
  cellSpacer: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
    paddingVertical: 3,
    paddingHorizontal: 10,
    flexGrow: 1,
  },
  cellTitle: {
    orderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
    paddingVertical: 3,
    paddingHorizontal: 10,
    flexGrow: 1,
    backgroundColor: 'yellow',
    fontSize: 12,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkbox: {
    borderWidth: 1,
    marginHorizontal: 3,
    paddingHorizontal: 3,
  }
});

const RunSheet = (props) => {
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text>COUNTY:</Text>
            </View>
            <View style={styles.cell}>
              <Text>{props.project?.county}</Text>
            </View>
            <View style={styles.cellSpacer}>
            </View>
            <View style={styles.cell}>
              <Text>STATE:</Text>
            </View>
            <View style={styles.cell}>
              <Text>{props.project?.state}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text>SEARCHED FROM:</Text>
            </View>
            <View style={styles.cell}>
              <Text>{props.runSheets[0]?.searched_from ? dateFormat(new Date(props.runSheets[0]?.searched_from), 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={styles.cell}>
              <Text>TO:</Text>
            </View>
            <View style={styles.cell}>
              <Text>{props.runSheets[0]?.searched_to ? dateFormat(new Date(props.runSheets[0]?.searched_to), 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={styles.cellSpacer}></View>
            <View style={styles.cell}>
              <Text>NAME:</Text>
            </View>
            <View style={styles.cell}>
              <Text>DBTSearch</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellSpacer}></View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, padding: 0, paddingTop: 5}}>
              <Text style={{fontSize: 12, paddingHorizontal: 5}}>{props.gf}</Text>
              <View style={{width: '100%', backgroundColor: 'yellow', paddingHorizontal: 5}}>
                <Text style={{fontSize: 9}}>ORDER NUMBER</Text>
              </View>
            </View>
            <View style={{...styles.cell, padding: 0, paddingTop: 5, flexGrow: 1}}>
              <Text style={{fontSize: 12, paddingHorizontal: 5}}>GFXXXXXXX</Text>
              <View style={{width: '100%', backgroundColor: 'yellow', paddingHorizontal: 5}}>
                <Text style={{fontSize: 9}}>FULL NAME(S) OF BORROWER(S)</Text>
              </View>
            </View>
            <View style={{...styles.cell, padding: 0, paddingTop: 5, flexGrow: 1}}>
              <Text style={{fontSize: 12, paddingHorizontal: 5}}>GFXXXXXXX</Text>
              <View style={{width: '100%', backgroundColor: 'yellow', paddingHorizontal: 5}}>
                <Text style={{fontSize: 9}}>ADDRESSES GIVEN</Text>
              </View>
            </View>
            <View style={{...styles.cell, padding: 0, paddingTop: 5}}>
              <Text style={{fontSize: 12, paddingHorizontal: 5}}>{dateFormat(new Date(), 'mm/dd/yyyy')}</Text>
              <View style={{width: '100%', backgroundColor: 'yellow', paddingHorizontal: 5}}>
                <Text style={{fontSize: 9}}>TODAY'S DATE</Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellSpacer}></View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 75}}>
              <Text>GRANTEE:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.grantee + ', ' + props.warrantyDeeds[0]?.grantee_sufix}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 75}}>
              <Text>GRANTOR:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.grantor + ', ' + props.warrantyDeeds[0]?.grantor_sufix}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 75}}>
              <Text>DATED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.dated ? dateFormat(props.warrantyDeeds[0]?.dated, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>RECORDED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.recorded ? dateFormat(props.warrantyDeeds[0]?.recorded, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>INST#:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.book ? props.warrantyDeeds[0]?.book + ' ' + props.warrantyDeeds[0]?.page : null}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 75}}>
              <Text>NOTES:</Text>
            </View>
             <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.warrantyDeeds[0]?.notes}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellTitle}>
              <Text>TAXES</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 125}}>
              <Text>ACCT# PIN# TMS#:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text></Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 125}}>
              <Text>TAXES PAID THRU:</Text>
            </View>
            <View style={{...styles.cell, width: 50}}>
              <Text>{props.taxes[0]?.paid_thru_year_1}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>AMT:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>$ {props.taxes[0]?.paid_thru_value_1}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 125}}>
              <Text> </Text>
            </View>
            <View style={{...styles.cell, width: 50}}>
              <Text>{props.taxes[0]?.paid_thru_year_2}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>AMT:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>$ {props.taxes[0]?.paid_thru_value_2}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 125}}>
              <Text>TAX DUE:</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>YR.</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>{props.taxes[0]?.due_year_1}</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>$ {props.taxes[0]?.due_value_1}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 125}}>
              <Text> </Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>YR.</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>{props.taxes[0]?.due_year_1}</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>$ {props.taxes[0]?.due_value_1}</Text>
            </View>
          </View>
        <View style={styles.row}>
          <View style={{...styles.cell, width: 125}}>
            <Text>LAND VALUE:</Text>
          </View>
          <View style={{...styles.cell, flexGrow: 1}}>
            <Text>$ {props.taxes[0]?.land_value}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{...styles.cell, width: 125}}>
            <Text>IMPROVEMENTS:</Text>
          </View>
          <View style={{...styles.cell, flexGrow: 1}}>
            <Text>$ {props.taxes[0]?.improvements}</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={{...styles.cell, width: 125}}>
            <Text>TOTAL ASSETS:</Text>
          </View>
          <View style={{...styles.cell, flexGrow: 1}}>
            <Text>$ {props.taxes[0]?.total_assessed}</Text>
          </View>
        </View>

          <View style={styles.row}>
            <View style={styles.cellTitle}>
              <Text>DEEDS OF TRUST</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>EXECUTED BY:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.executed_by}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>BENEFICIARY:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.beneficiary}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>TRUSTEE:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.trustee}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>AMOUNT:</Text>
            </View>
            <View style={{...styles.cell, width: 85}}>
              <Text>$ {props.deedOfTrusts[0]?.amount}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>DATED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.dated ? dateFormat(props.deedOfTrusts[0]?.dated, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>RECORDED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.recorded ? dateFormat(props.deedOfTrusts[0]?.recorded, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <View style={styles.line}>
                <Text style={styles.checkbox}>{props.deedOfTrusts[0]?.open ? 'X' : null}</Text>
                <Text>OPEN:</Text>
              </View>
            </View>
            <View style={{...styles.cell, width: 85}}>
              <Text>$ {props.deedOfTrusts[0]?.open_amount}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>INST#:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.book + ' ' + props.deedOfTrusts[0]?.page}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>MATURITY DATE:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.maturity_date ? dateFormat(props.deedOfTrusts[0]?.maturity_date, 'mm/dd/yyyy') : null}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>ASSIGNED TO:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.assigned_to}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>NOTES:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[0]?.assigned_to}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellSpacer}>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>EXECUTED BY:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.executed_by}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>BENEFICIARY:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.beneficiary}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>TRUSTEE:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.trustee}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>AMOUNT:</Text>
            </View>
            <View style={{...styles.cell, width: 85}}>
              <Text>$ {props.deedOfTrusts[1]?.amount}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>DATED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.dated ? dateFormat(props.deedOfTrusts[1]?.dated, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>RECORDED:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.recorded ? dateFormat(props.deedOfTrusts[1]?.recorded, 'mm/dd/yyyy') : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <View style={styles.line}>
                <Text style={styles.checkbox}>{props.deedOfTrusts[1]?.open ? 'X' : ' '}</Text>
                <Text>OPEN:</Text>
              </View>
            </View>
            <View style={{...styles.cell, width: 85}}>
              <Text>$ {props.deedOfTrusts[1]?.open_amount}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>INST#:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.book ? props.deedOfTrusts[1]?.book + ' ' + props.deedOfTrusts[1]?.page : null}</Text>
            </View>
            <View style={{...styles.cell}}>
              <Text>MATURITY DATE:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.maturity_date ? dateFormat(props.deedOfTrusts[1]?.maturity_date, 'mm/dd/yyyy') : null}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>ASSIGNED TO:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.assigned_to}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>NOTES:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.deedOfTrusts[1]?.assigned_to}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellTitle}>
              <Text>LINES (JUDGMENTS, MECHANIC, FEDERAL/STATE TAX, LP, DIVORCE, ETC)</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>ANY FOUND:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <View style={styles.line}>
                <Text style={styles.checkbox}>{props.judgements[0]?.judgement_exists ? 'X' : ' '}</Text>
                <Text>YES</Text>
                <Text style={styles.checkbox}>{!props.judgements[0]?.judgement_exists ? 'X' : ' '}</Text>
                <Text>NO</Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, width: 115}}>
              <Text>TO WHOM:</Text>
            </View>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>{props.stops[0]?.open_pmm ? 'OPEN PMM' : null}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={{...styles.cell, flexGrow: 1}}>
              <View style={styles.line}>
                <Text style={styles.checkbox}>{props.stops[0]?.developer ? 'X' : ' '}</Text>
                <Text>Developer</Text>
                <Text> </Text>
                <Text style={styles.checkbox}>{props.stops[0]?.years_30 ? 'X' : ' '}</Text>
                <Text>30 YRS</Text>
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cellSpacer}>
            </View>
          </View>
          <View style={{...styles.row}}>
            <View style={{...styles.cell, flexGrow: 1}}>
              <Text>Notes: **** 20-YR FEDERAL TAX LINE HAS BEEN PERFORMED ****</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default RunSheet;
