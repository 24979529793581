import React from 'react';
import {
  ResponsiveContainer,
  PieChart as RPieChart,
  Pie,
  Cell,
} from 'recharts';


const COLORS = [
  'green',
  'red',
];

const PieChart = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RPieChart width={400} height={400}>
        <Pie
          data={props.data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          startAngle={180}
          endAngel={0}
        />
        {props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </RPieChart>
    </ResponsiveContainer>
  );
}

export default PieChart;
