export const projectPriorityDisplay = (priority) => {
  switch (priority) {
    case 1: return 'Top';
    case 2: return 'High';
    case 3: return 'Normal';
    default: return 'Unknown';
  }
}

export const projectStatusDisplay = (projectStatus) => {
  switch (projectStatus) {
    case 0: return 'Problem';
    case 1: return 'Created';
    case 2: return 'In Process';
    case 3: return 'Finished';
    default: return 'Unknown';
  }
}
