import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  useLocation
} from "react-router-dom";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import axios from '../../../axios-main';
import axiosInvoice from '../../../axios-invoice'
import * as actions from '../../../store/actions';
import * as constants from '../../../constants';

const openBase64NewTab = (base64Pdf) => {
  const blob = base64toBlob(base64Pdf);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, "invoice.pdf");
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

const base64toBlob = (base64Data) => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: "application/pdf" });
}

const openPdfNewTab = (pdf) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(pdf, "invoice.pdf");
  } else {
    const blobUrl = URL.createObjectURL(pdf);
    window.open(blobUrl);
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const priorities = [
  {
    value: 3,
    text: 'Normal',
  },
  {
    value: 2,
    text: 'High',
  },
  {
    value: 1,
    text: 'Top',
  },
];

const ProjectInvoice = (props) => {
  const classes = useStyles();

  const { search } = useLocation();

  const { project } = props;

  const [invoice, setInvoice] = useState({
    clients_id: 0,
    number: '',
    item_text_0: 'Service Fee',
    item_text_1: null,
    item_text_2: null,
    item_text_3: null,
    item_text_4: null,
    item_text_5: null,
    item_value_0: 0.0,
    item_value_1: 0.0,
    item_value_2: 0.0,
    item_value_3: 0.0,
    item_value_4: 0.0,
    item_value_5: 0.0,
    total: 0.0,
    paid: false,
    updates_id: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [isCreatedSuccess, setIsCreatedSuccess] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);

  const [clients, setClients] = useState([]);
  const [updates, setUpdates] = useState([]);

  const [showItems, setShowItems] = useState([true, false, false, false, false]);

  useEffect(() => {
    props.loadProject(props.token, props.match.params.id);
    (async () => {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + props.token,
          }
        }

        const clients = (await axios.get('/clients', config)).data;
        setClients(clients);

        const updates = (await axios.get(`/projects/${props.match.params.projectId}/updates`, config)).data;
        setUpdates(updates);

        if (props.match.params.id) {
          const inv = (await axios.get(`/projects/${props.match.params.projectId}/invoices/${props.match.params.id}`, config)).data;
          if (inv) {
            setInvoice({
              ...inv,
            });
            setShowItems([
              true,
              inv.item_text_1 !== null ? true : false,
              inv.item_text_2 !== null ? true : false,
              inv.item_text_3 !== null ? true : false,
              inv.item_text_4 !== null ? true : false,
              inv.item_text_5 !== null ? true : false,
            ]);
            setIsNew(false);
          }
        } else {
          const query = new URLSearchParams(search);
          const update = query.get('update');

          try {
            const invoices = (await axios.get(`/projects/${props.match.params.projectId}/invoices`, config)).data;
            const invoice = invoices.find(el => el.updates_id == update);
            if (invoice && update) {
              const id = invoice.id;
              props.history.replace(`/projects/${props.match.params.projectId}/invoices/${id}`);
            }
          } catch (err) {
            console.log(err);
          }

          if (update) {
            setInvoice({
              ...invoice,
              updates_id: update,
            });
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        props.history.goBack();
      }
    })();
  }, []);

  const { clients_id } = invoice;
  useEffect(async () => {
    if (project && clients_id && invoice.item_value_0 < 0.00001) {
      try {
        const config = {
          headers: {
            Authorization: 'Bearer ' + props.token,
          }
        }
        //const response = await axios.get(`/clients/0/prices?client_name=${props.project.customer}`, config);
        const response = await axios.get(`/clients/${clients_id}/prices`, config);
        const prices = response.data;
        const price = prices.find(el => el.search_type === project.search_type);
        const updatedInvoice = {
          ...invoice,
          item_value_0: price ? price.price : 0.0,
        }
        setInvoice(updatedInvoice);
      } catch (error) {
        console.log(error);
      }
    }
  }, [clients_id, project]);

  const createInvoice = async () => {
    const client = clients.find(el => el.id == invoice.clients_id);

    const today = new Date();
    const due = new Date();
    due.setDate(today.getDate() + 7);

    const products = [];
    [0, 1, 2, 3, 4, 5].forEach((el) => {
      if (invoice[`item_text_${el}`] === null) {
        return;
      }

      products.push({
        quantity: 1,
        description: invoice[`item_text_${el}`],
        price: parseFloat(invoice[`item_value_${el}`]),
        taxRate: 0,
      });
    });

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    const project = (await axios.get(`/projects/${props.match.params.projectId}`, config)).data;

    const data = {
      images: {
        logo: `${constants.FRONT_URL}/images/logo.png`,
      },
      sender: {
        company: 'DBT SEARCH LLC',
        address: '333-H CIRCLE AVE',
        city: 'NC 28207',
        zip: 'CHARLOTTE USA',
        //country: 'USA',
        custom1: '704-587-5826',
        custom2: 'customerservice@dbtsearch.com ',
      },
      client: {
        company: client.name,
        address: client.address,
        zip: client.city + ' ' + client.country,
        city: client.zip,
        //country: client.country,
        custom1: project.gf,
        custom2: project.borrower_name,
        custom3: project.county + " " + project.state,
      },
      products: products,
      information: {
        number: invoice.number,
        date: today.toISOString().split('T')[0],
        dueDate: due.toISOString().split('T')[0],
      },
      //'bottom-notice': 'Kindly pay your invoice within 15 days.',
    }

    const result = await axiosInvoice.post('/invoice', { data: data }, {
      responseType: 'blob',
    })
    openPdfNewTab(result.data)
  }

  const onShowItemChangedHandler = (event, key) => {
    const updatedItems = [...showItems];
    updatedItems[key] = event.target.checked;
    setShowItems(updatedItems);

    if (!event.target.checked) {
      setInvoice({
        ...invoice,
        [`item_text_${key}`]: null,
        [`item_value_${key}`]: 0,
      });
    }
  }

  const onValueChangedHandler = (event, key) => {
    let value = event.target.value;

    if (key === 'paid') {
      value = event.target.checked;
    }

    const updatedInvoice = {
      ...invoice,
      [key]: value,
    }

    if (key === 'clients_id') {
      const client = clients.find(el => el.id === value);
      updatedInvoice.number = client.short_name + client.next_invoice_number;
    }

    const val0 = parseFloat(updatedInvoice.item_value_0);
    const val1 = parseFloat(updatedInvoice.item_value_1);
    const val2 = parseFloat(updatedInvoice.item_value_2);
    const val3 = parseFloat(updatedInvoice.item_value_3);
    const val4 = parseFloat(updatedInvoice.item_value_4);
    const val5 = parseFloat(updatedInvoice.item_value_5);

    const total = val0 + val1 + val2 + val3 + val4 + val5;

    updatedInvoice.total = total;

    setInvoice(updatedInvoice);
  }

  const onSaveClickedHandler = async () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    const data = {
      clients_id: invoice.clients_id,
      number: invoice.number,
      item_text_0: invoice.item_text_0,
      item_text_1: invoice.item_text_1,
      item_text_2: invoice.item_text_2,
      item_text_3: invoice.item_text_3,
      item_text_4: invoice.item_text_4,
      item_text_5: invoice.item_text_5,
      item_value_0: invoice.item_value_0,
      item_value_1: invoice.item_value_1,
      item_value_2: invoice.item_value_2,
      item_value_3: invoice.item_value_3,
      item_value_4: invoice.item_value_4,
      item_value_5: invoice.item_value_5,
      total: invoice.total,
      paid: invoice.paid,
      updates_id: invoice.updates_id,
    }
    try {
      if (isNew) {
        const result = await axios.post(`/projects/${props.match.params.projectId}/invoices`, data, config);
        await createInvoice();
        setIsLoading(false);
        setIsCreatedSuccess(true);
      } else {
        data['id'] = invoice.id;
        const result = await axios.put(`/projects/${props.match.params.projectId}/invoices/${invoice.id}`, data, config);
        await createInvoice();
        setIsLoading(false);
        setIsCreatedSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setIsErrorOccurred(true);
    }
  }

  const onFinishCreateingProjectHandler = () => {
    props.history.goBack();
  }

  const clientOptions = clients.map((el) => {
    return (
      <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
    );
  });

  let content = <CircularProgress color="secondary" size={100} />;
  if (!isLoading) {
    const fields = [0, 1, 2, 3, 4, 5].map(el => {
      let toggle = null;
      if (el > 0) {
        toggle = (
          <>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                value={`item_toggle_${el}`}
                control={
                  <Switch
                    color="primary"
                    checked={showItems[el]}
                    onChange={(event) => onShowItemChangedHandler(event, el)} />
                }
                label={`Item ${el}`}
                labelPlacement="right" />
            </FormControl>
            <br />
          </>
        );
      }

      let field = null;
      if (showItems[el]) {
        field = (
          <>
            <FormControl className={classes.formControl}>
              <TextField label="Description" value={invoice[`item_text_${el}`]} onChange={(event) => onValueChangedHandler(event, `item_text_${el}`)} />
            </FormControl>
            <br />
            <FormControl className={classes.formControl}>
              <TextField label="Amount" value={invoice[`item_value_${el}`]} onChange={(event) => onValueChangedHandler(event, `item_value_${el}`)} />
            </FormControl>
            <br />
          </>
        );
      }

      return (
        <>
          {toggle}
          {field}
          <br />
        </>
      );
    });

    const types = [<MenuItem key={0} value={0}>SEARCH</MenuItem>];
    updates.forEach((el, index) => {
      types.push(<MenuItem key={el.id} value={el.id}>UPDATE {index + 1}</MenuItem>);
    });

    content = (
      <>
        <form>
          <FormControl className={classes.formControl}>
            <InputLabel>Client</InputLabel>
            <Select value={invoice.clients_id} onChange={(event) => onValueChangedHandler(event, 'clients_id')}>
              {clientOptions}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <InputLabel>Type</InputLabel>
            <Select value={invoice.updates_id} onChange={(event) => onValueChangedHandler(event, 'updates_id')}>
              {types}
            </Select>
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <TextField label="Number" value={invoice.number} onChange={(event) => onValueChangedHandler(event, 'number')} />
          </FormControl>
          <br />
          {fields}
          <FormControl className={classes.formControl}>
            <TextField label="Total" value={invoice.total} onChange={(event) => onValueChangedHandler(event, 'total')} />
          </FormControl>
          <br />
          <FormControl className={classes.formControl}>
            <FormControlLabel
              value="paid"
              control={
                <Switch
                  color="primary"
                  checked={invoice.paid}
                  onChange={(event) => onValueChangedHandler(event, 'paid')} />
              }
              label="Paid"
              labelPlacement="right" />
          </FormControl>
        </form>
        <br />
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClickedHandler}>Save and Generate</Button>
      </>
    );
  }

  return (
    <Layout title="Projects">
      <CustomCard>
        {content}
      </CustomCard>

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="Invoice ganerated successfully"
        onDialogClosed={onFinishCreateingProjectHandler} />
      <Alert
        show={isErrorOccurred}
        title="Error"
        message="Error ocured while generating invoice"
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    project: state.project.project,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProject: (token, id) => dispatch(actions.projectLoad(token, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInvoice);
