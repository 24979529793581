import { updateObject } from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  role: null,
  isTryingToLogin: false,
}

const authStart = (state, action) => {
  return updateObject(state, {
    isTryingToLogin: true,
    isAuthenticated: false,
  });
}

const authSuccess = (state, action) => {
  return updateObject(state, {
    isTryingToLogin: false,
    isAuthenticated: true,
    user: action.user,
    token: action.token,
    role: action.user.role,
  });
}

const authFail = (state, action) => {
  return updateObject(state, {
    isTryingToLogin: false,
    isAuthenticated: false,
    user: null,
    token: null,
    role: null,
  });
}

const authLogout = (state, action) => {
  return updateObject(state, {
    isTryingToLogin: false,
    isAuthenticated: false,
    user: null,
    token: null,
    role: null,
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    default: return state;
  }
}

export default reducer;
