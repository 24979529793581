import axios from 'axios';
import * as constants from './constants';

/*
const instance = axios.create({
  baseURL: 'http://localhost:8001/api/v1/',
});
*/

/*
const instance = axios.create({
  baseURL: 'https://pdf.dbtsearch.com/api/v1/',
});
*/

const instance = axios.create({
  baseURL: constants.INVOICE_API_URL,
});

export default instance;
