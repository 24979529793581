import { updateObject } from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showDrawer: false,
}

const toggleDrawer = (state, action) => {
  return updateObject(state, {
    showDrawer: !state.showDrawer,
  });
}

const load = (state, action) => {
  return updateObject(state, {
    showDrawer: action.showDrawer,
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_TOGGLE_DRAWER: return toggleDrawer(state, action);
    case actionTypes.UI_LOAD: return load(state, action);
    default: return state;
  }
}

export default reducer;
