import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';
import ReactExport from 'react-export-excel';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import BarChart from '../../components/Charts/BarChart/BarChart';
import LineChart from '../../components/Charts/LineChart/LineChart';
import PieChart from '../../components/Charts/PieChart/PieChart';
import axios from '../../axios-main';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStyles = makeStyles({
  gridContainer: {
    padding: 20,
  },
  formControl: {
    width: 200,
  },
});

const columns = [
  'GF',
  'Client',
  'Number',
  'Date',
  'Type',
  'Amount',
  'Paid',
  'Paid Amount',
]

const Financy = (props) => {
  const classes = useStyles();

  const [paidByCustomer, setPaidByCustomer] = useState([]);
  const [unpaidByCustomer, setUnpaidByCustomer] = useState([]);
  const [total, setTotal] = useState([]);

  const [from, setFrom] = useState('2021-01-01');
  const [to, setTo] = useState(dateFormat(new Date(), 'yyyy-mm-dd'));

  const [invoices, setInvoices] = useState([]);

  const getStatistics = (from = '2021-01-01', to = dateFormat(new Date(), 'yyyy-mm-dd')) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    axios.get(`/financy_statistics?from=${from}&to=${to}`, config)
      .then((response) => {
        setPaidByCustomer(response.data.paid_by_customer.map((el) => {
          return {
            label: el.name,
            value: el.count,
          }
        }));
        setUnpaidByCustomer(response.data.unpaid_by_customer.map((el) => {
          return {
            label: dateFormat(new Date(el.date), 'mmmm dS'),
            value: el.count,
          }
        }));
      })
      .catch((error) => console.log(error));
  }

  const getInvoices = async () => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }

      const response = await axios.get(`/financy_statistics/invoices`, config);
      setInvoices(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    console.log(from, to);
    getStatistics(from, to);
    getInvoices();
  }, [from, to]);

  const excelData = invoices.map((el) => {
    return [
      el.project && el.project.gf ? el.project.gf : '',
      el.client && el.client.name ? el.client.name : '',
      el.number ? el.number : '',
      dateFormat(new Date(el.created_at), 'mm/dd/yyyy'),
      el.updates_id ? 'UPDATE' : 'SEARCH',
      el.total ? el.total.toString() : '0',
      el.paid ? 'True' : 'Fasle',
      el.paid && el.total ? el.total.toString() : '0',
    ];
  });

  const dataSet = [
    {
      columns: columns,
      data: excelData,
    },
  ];

  console.log(dataSet);

  return (
    <Layout title="Dashboard">
      <CustomCard title="Statistics">
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12}>
            <CustomCard>
              <TextField
                className={classes.formControl}
                label="From"
                value={from}
                type="date"
                InputLabelProps={{shrink: true}}
                onChange={(event) => setFrom(event.target.value)} />

              &nbsp;
              &nbsp;
              &nbsp;

              <TextField
                className={classes.formControl}
                label="To"
                value={to}
                type="date"
                InputLabelProps={{shrink: true}}
                onChange={(event) => setTo(event.target.value)} />

              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;

              <ExcelFile element={<Button color="primary" variant="contained">Export to Excel</Button>} filename="dbtsearch">
                <ExcelSheet dataSet={dataSet} name="Invoices" />
              </ExcelFile>
            </CustomCard>
          </Grid>
          <Grid item xs={12}>
            <CustomCard title="Total">
              <div style={{width: '100%', height: 300}}>
                <PieChart data={total} />
              </div>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard title="Paid by Customer">
              <div style={{width: '100%', height: 300}}>
                <BarChart data={paidByCustomer} />
              </div>
            </CustomCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomCard title="Unpaid by Customer">
              <div style={{width: '100%', height: 300}}>
                <LineChart data={unpaidByCustomer} />
              </div>
            </CustomCard>
          </Grid>
        </Grid>
      </CustomCard>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(Financy);
