import React, { useState } from 'react';
import {
  Button,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import TabPanel from '../../components/TabPanel/TabPanel';
import RunSheet from './RunSheet/RunSheet';
import OptionsAdministration from './OptionsAdministration/OptionsAdministration';

const Settings = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  return (
    <Layout title="Settings">
      <CustomCard>
        <AppBar position="static">
            <Tabs value={currentTabIndex} onChange={(event, value) => setCurrentTabIndex(value)}>
              <Tab label="REPORT PDF" />
              <Tab label="Data" />
            </Tabs>
        </AppBar>
        <TabPanel value={currentTabIndex} index={0}>
          <RunSheet />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <OptionsAdministration />
        </TabPanel>
      </CustomCard>
    </Layout>
  );
}

export default Settings;
