import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/PictureAsPdf';

import * as actions from '../../../store/actions';
import { states, counties } from '../../../data/data';
import DNDCheckableList from '../../../components/DNDCheckableList/DNDCheckableList';
import CustomCard from '../../../hoc/Card/Card';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 700,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const ReportData = (props) => {
  const classes = useStyles();

  const [generate, setGenerate] = useState(true);

  useEffect(() => {
    if (props.runSheets.length === 0) {
      props.addRunSheet({
        state: 'TX',
        county: '',
        full_name_of_borrowers: '',
        address: '',
        searched_from: '',
        searched_to: '',
        name: 'DBTSearch LLC',
        note_1: '',
        note_2: '',
      });
    }
  }, []);

  const updateFieldHandler = (event, index, field) => {
    props.updateRunSheet(index, field, event.target.value);
  }

  const onItemReorderedHandler = (startIndex, endIndex) => {
    props.reorderDocuments(startIndex, endIndex);
  }

  const inReportChangedHandler = (index, value) => {
    props.updateDocument(index, 'in_report', value);
  }

  const onSaveClicked = () => {
    props.saveAllData(props.project, props.token);
  }

  const onSaveAndGenerateClicked = () => {
    props.saveAndGenerate(props.project, props.token, generate);
  }

  const documentList = props.documents.map((el) => {
    return {
      id: el.id,
      text: el.name,
      inReport: el.in_report,
    }
  });

  const runSheetData = props.runSheets.map((el, index) => {
    return (
      <>
        <Grid item xs={12}>
          <FormControlLabel
            value="Merge"
            control={<Switch color="primary" checked={generate} onChange={(event) => setGenerate(event.target.checked)} />}
            label="Merge"
            labelPlacement="right" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Searched From:"
            value={el.searched_from}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => updateFieldHandler(event, index, 'searched_from')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Searched To:"
            value={el.searched_to}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => updateFieldHandler(event, index, 'searched_to')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Note"
            value={el.note_1}
            onChange={(event) => updateFieldHandler(event, index, 'note_1')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Note"
            value={el.note_2}
            onChange={(event) => updateFieldHandler(event, index, 'note_2')} />
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          Number of Documents: {documentList.length}
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <CustomCard>
            <DNDCheckableList items={documentList} onItemReordered={onItemReorderedHandler} inReportChanged={inReportChangedHandler} />
          </CustomCard>
        </Grid>
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          {runSheetData}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClicked}>Save</Button>
        &nbsp;
        <Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={onSaveAndGenerateClicked}>Save and Generate</Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    runSheets: state.project.runSheets,
    documents: state.project.documents,
    project: state.project,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addRunSheet: (runSheet) => dispatch(actions.projectArrayAdd('runSheets', runSheet)),
    updateRunSheet: (index, field, value) => dispatch(actions.projectArrayUpdate('runSheets', index, field, value)),
    updateDocument: (index, field, value) => dispatch(actions.projectArrayUpdate('documents', index, field, value)),
    updateProject: (field, value) => dispatch(actions.projectFieldUpdate(field, value)),
    reorderDocuments: (startIndex, endIndex) => dispatch(actions.projectDocumentReorder(startIndex, endIndex)),
    saveAndGenerate: (project, token, merge) => dispatch(actions.projectSaveAndGenerate(project, token, merge)),
    saveAllData: (project, token) => dispatch(actions.projectSaveAllData(project, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportData);
