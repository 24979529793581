import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';

import * as actions from '../../../store/actions';
import * as constants from '../../../constants';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 300,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    flexGrow: 1,
  },
  details: {
  },
  upload: {
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  activeDocument: {
    color: theme.palette.primary.light,
  },
}));

const DocumentsDrawer = (props) => {
  const classes = useStyles();

  const uploadsInputRef = useRef();

  const [ selectedId, setSelectedId ] = useState(-1);

  const onFileChangeHandler = (event) => {
    Array.from(event.target.files).forEach((el) => {
      props.uploadDocument(props.projectId, el, props.token);
    });
  }

  const onFileUploadClickedHandler = () => {
    uploadsInputRef.current.click();
  }

  const onPreviewClickedHandler = (name) => {
    window.open(constants.DOCUMENTS_BASE_URL + '/' + name, Math.random().toString(), 'height=800,width=800');
  }

  const onDeleteClickHandler = (id) => {
    setSelectedId(-1);
    props.deleteDocument(props.projectId, id, props.token);
  }

  const documents = props.documents.map((el) => {
    return (
      <ListItem button key={el.id} onClick={() => setSelectedId(el.id)} className={el.id === selectedId ? classes.activeDocument : null}>
        <ListItemIcon className={el.id === selectedId ? classes.activeDocument : null}><DescriptionIcon /></ListItemIcon>
        <ListItemText primary={el.name} />
      </ListItem>
    );
  });

  let info = null;
  if (selectedId >= 0) {
    const selectedDocument = props.documents.find(el => el.id === selectedId);
    info = (
      <>
        <Divider />
        <div className={classes.details}>
          <Typography>
            Filename: {selectedDocument.name}
          </Typography>
          <div className={classes.upload}>
            <Button variant="contained" color="primary" startIcon={<VisibilityIcon />} onClick={() => onPreviewClickedHandler(selectedDocument.filename)}>Preview</Button>
            &nbsp;
            <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => onDeleteClickHandler(selectedDocument.id)}>Delete</Button>
          </div>
        </div>
      </>
    );
  }

  const uploadButton = props.isLoading
                      ? <CircularProgress color="secondary" />
                      : <Button variant="contained" color="primary" startIcon={<PublishIcon />} onClick={onFileUploadClickedHandler}>Upload</Button>;

  return (
    <Drawer anchor="right" open={props.show} onClose={props.onDrawerClosed}>
      <div className={classes.container}>
        <div className={classes.list}>
          <List>
            {documents}
          </List>
        </div>
        {info}
        <Divider />
        <div className={classes.upload}>
          <input type="file" name="file" onChange={onFileChangeHandler} ref={uploadsInputRef} style={{ display: 'none' }} multiple />
          {uploadButton}
        </div>
      </div>
    </Drawer>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    documents: state.project.documents,
    isLoading: state.project.documentsLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadDocument: (id, file, token) => dispatch(actions.projectDocumentUpload(id, file, token)),
    deleteDocument: (projectId, documentId, token) => dispatch(actions.projectDocumentDelete(projectId, documentId, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsDrawer);
