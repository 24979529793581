import React from 'react';
import {
  ResponsiveContainer,
  LineChart as RLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts';

const LineChart = (props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RLineChart
        data={props.data}
        margin={{
          top: 5,
          bottom: 5,
          right: 30,
          left: 20,
        }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Line dataKey="value" fill="#8884d8" />
      </RLineChart>
    </ResponsiveContainer>
  );
}

export default LineChart;
