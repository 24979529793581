import axios from 'axios';
import * as constants from './constants';

/*
const instance = axios.create({
  baseURL: 'http://localhost:8000/api/v1/',
});
*/

/*
const instance = axios.create({
  baseURL: 'https://api.dbtsearch.com/api/v1/',
});
*/

const instance = axios.create({
  //baseURL: 'https://api.dbtsearch.com/api/v1/',
  baseURL: constants.BASE_URL + '/api/v1/',
});

export default instance;
