import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import DataTable from '../../../components/DataTable/DataTable';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },
}));

const EditClient = (props) => {
  const classes = useStyles();

  const [ client, setClient ] = useState({});
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isNew, setIsNew ] = useState(true);
  const [ isCreatedSuccess, setIsCreatedSuccess ] = useState(false);
  const [ isErrorOccurred, setIsErrorOccurred ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('Error ocured while creating user');

  const [ prices, setPrices ] = useState([]);

  useEffect(async () => {
    if (props.match.params.id) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }
      try {
        let response = await axios.get(`/clients/${props.match.params.id}`, config);
        const clientData = response.data;
        response = await axios.get(`/clients/${props.match.params.id}/prices`, config);
        const pricesData = response.data;
        response = await axios.get(`/search_type_options`, config);
        const searchTypes = response.data;

        const updatedPrices = searchTypes.map((el) => {
          return {
            search_type: el.text,
            price: 0.0,
          }
        });

        pricesData.forEach((el) => {
          const index = updatedPrices.findIndex(e => e.search_type === el.search_type);
          updatedPrices[index].price = el.price;
        });

        setIsNew(false);
        setIsLoading(false);
        setClient(clientData);
        setPrices(updatedPrices);
      } catch (error) {
        console.log(error);
        props.history.goBack();
      }
    } else {
      setIsNew(true);
      setIsLoading(false);
      setClient({
      });
    }
  }, []);

  const onValueChangedHandler = (event, key) => {
    setClient({
      ...client,
      [key]: event.target.value,
    });
  }

  const onPriceValueChangedHandler = (event, key) => {
    const updatedPrices = [ ...prices ];
    const index = updatedPrices.findIndex(el => el.search_type === key);
    updatedPrices[index].price = event.target.value;
    setPrices(updatedPrices);
  }

  const onSaveClickedHandler = () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    const data = {
      name: client.name,
      short_name: client.short_name,
      address: client.address,
      zip: client.zip,
      city: client.city,
      country: client.country,
      next_invoice_number: client.next_invoice_number,
    }
    if (isNew) {
      axios.post('/clients', data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsErrorOccurred(true);
          setErrorMessage('Error ocured while creating client');
        });
    } else {
      data.id = props.match.params.id;
      axios.put(`/clients/${data.id}`, data, config)
        .then((response) => {
          setIsLoading(false);
          setIsCreatedSuccess(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsErrorOccurred(true);
          setErrorMessage('Error ocured while saveing client');
        });
    }
  }

  const onSavePricesClickedHandler = async () => {
    setIsLoading(true);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    try {
      prices.forEach(data => {
        axios.post(`/clients/${props.match.params.id}/prices`, data, config);
      });

      setIsLoading(false);
      setIsCreatedSuccess(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setIsErrorOccurred(true);
      setErrorMessage('Error ocured while saving pries');
    }
  }

  const onFinishCreateingUserHandler = () => {
    props.history.push('/clients');
  }

  let content = (
    <>
      <form style={{ width: '100%' }}>
        <FormControl className={classes.formControl}>
          <TextField label="Name" value={client.name} onChange={(event) => onValueChangedHandler(event, 'name')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Short Name" value={client.short_name} onChange={(event) => onValueChangedHandler(event, 'short_name')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Address" value={client.address} onChange={(event) => onValueChangedHandler(event, 'address')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Zip" value={client.zip} onChange={(event) => onValueChangedHandler(event, 'zip')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="City" value={client.city} onChange={(event) => onValueChangedHandler(event, 'city')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Country" value={client.country} onChange={(event) => onValueChangedHandler(event, 'country')} />
        </FormControl>
        <br />
        <FormControl className={classes.formControl}>
          <TextField label="Next Invoice Number" value={client.next_invoice_number} onChange={(event) => onValueChangedHandler(event, 'next_invoice_number')} />
        </FormControl>
        <br />
      </form>
      <br />
      <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClickedHandler}>Save</Button>
    </>
  );

  if (isLoading) {
    content = <CircularProgress color="secondary" size={100} />;
  }

  let pricesContent = null;
  if (props.match.params.id) {
    const fields = prices.map((el) => {
      return (
        <>
          <FormControl className={classes.formControl}>
            <TextField label={el.search_type} value={el.price} onChange={(event) => onPriceValueChangedHandler(event, el.search_type)} />
          </FormControl>
          <br />
        </>
      );
    });

    pricesContent = (
      <CustomCard title="Prices">
        <form style={{ width: '100%' }}>
          {fields}
          <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSavePricesClickedHandler}>Save</Button>
        </form>
      </CustomCard>
    );
  }

  return (
    <Layout title="Client">
      <CustomCard>
        {content}
      </CustomCard>
      <br />
      {pricesContent}

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="Client saved successfully"
        onDialogClosed={onFinishCreateingUserHandler} />
      <Alert
        show={isErrorOccurred}
        title="Error"
        message={errorMessage}
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(EditClient);
