import axios from '../../axios-main';
import * as actionTypes from './actionTypes';

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    const body = {
      email: username,
      password: password,
    };
    axios.post('/login', body)
      .then((response) => {
        dispatch(authSuccess(response.data.user, response.data.accesss_token));
      })
      .catch((error) => {
        dispatch(authFail());
      });
  }
}

export const authLogout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  return {
    type: actionTypes.AUTH_LOGOUT,
  }
}

export const authCheckState = () => {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    if (!user || !token) {
      dispatch(authFail());
      return;
    }

    dispatch(authSuccess(user, token));
  }
}

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  }
}

export const authSuccess = (user, token) => {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', token);

  return {
    type: actionTypes.AUTH_SUCCESS,
    user: user,
    token: token,
  }
}

export const authFail = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  return {
    type: actionTypes.AUTH_FAIL,
  }
}
