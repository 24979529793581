import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as actions from '../../../store/actions';

const Logout = (props) => {
  const {logout} = props;

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <>
      {!props.isAuth ? <Redirect to="/" /> : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.authLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
