import React from 'react';
import {
  Box,
} from '@material-ui/core';

const TabPanel = (props) => {
  const { children, value, index, ...other} = props;

  let tab = null;
  if (value === index) {
    tab = (
      <Box p={3}>
        {children}
      </Box>
    );
  }

  return (
    <div hidden={value !== index} >
      {tab}
    </div>
  );
}

export default TabPanel;
