import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  AppBar,
  Tabs,
  Tab,
  Button,
  Toolbar,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import TabPanel from '../../components/TabPanel/TabPanel';
import DocumentsDrawer from './DocumentsDrawer/DocumentsDrawer.js';
import Project from './Project/Project';
import WarrantyDeed from './WarrantyDeed/WarrantyDeed';
import DeedOfTrust from './DeedOfTrust/DeedOfTrust';
import Tax from './Tax/Tax';
import Judgement from './Judgement/Judgement';
import Stopped from './Stopped/Stopped';
import ReportData from './ReportData/ReportData';
import axios from '../../axios-main';
import * as actions from '../../store/actions';

import RunSheet from '../../pdf/RunSheet';

const useStyles = makeStyles((theme) => ({
  documentsButtonContainer: {
    flexGrow: 1,
  },
}));

const ProcessProject = (props) => {
  const classes = useStyles();

  const [ currentTabIndex, setCurrentTabIndex ] = useState(0);
  const [ showDocumentsDrawer, setShowDocumentsDrawer ] = useState(false);
  
  const historyListenCallback = useRef(null);

  const {
    generatePdf,
    pdf,
    history,
  } = props;

  useEffect(() => {
    props.loadProject(props.token, props.match.params.id);
    props.loadGrantorGraneeOptions(props.token);
    props.loadBeneficiaryOptions(props.token);
    props.loadTrusteeOptions(props.token);

    const data = {
      status: 2,
    }
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      },
    }
    axios.post(`/projects/${props.match.params.id}/status`, data, config)
      .then(() => {})
      .catch((error) => console.log(error))
  }, []);

  useEffect(() => {
    if (generatePdf) {
      props.generateReport(props.project, props.merge);
    }
  }, [generatePdf]);

  useEffect(() => {
    const loc = props.history.location.pathname;
    return () => {
      if (pdf && props.history.action === 'POP') {
        props.clearReport();
        props.history.replace(loc);
      }
    }
  });

  const continueToIndex = (index, endpoint, data) => {
    if (index >=7) {
      return;
    }

    props.saveData(props.projectState, data, endpoint, props.token);

    setCurrentTabIndex(index);
  }

  if (pdf) {
    const pdfURL = URL.createObjectURL(pdf);
    return (
      <Layout title="Projects">
        <CustomCard title={props.project.gf}>
          <iframe style={{ width: '100%', height: 700}} src={pdfURL} />
        </CustomCard>
      </Layout>
    );
  }

  if (props.isLoading) {
    return (
      <Layout title="Projects">
        <CircularProgress size={100} color="secondary" />
      </Layout>
    );
  }

  if (pdf) {
    const pdfURL = URL.createObjectURL(pdf);
    return (
      <Layout title="Projects">
        <CustomCard title={props.project.gf}>
          <iframe style={{ width: '100%', height: 700}} src={pdfURL} />
        </CustomCard>
      </Layout>
    );
  }

  return (
    <Layout title="Projects">
      <CustomCard title={props.project.gf}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Borrower: {props.project.borrower_name}
            </Grid>
            <Grid item xs={6}>
              Project Status: In Proccess
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Address: {props.project.address}
            </Grid>
            <Grid item xs={6}>
              Assigned To: {props.project.assigned_to?.name}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Loan Amount: {props.deedOfTrusts.length > 0 ? props.deedOfTrusts[0].amount : null}
            </Grid>
            <Grid item xs={6}>
              Improvement Value: {props.taxes.length > 0 ? props.taxes[0].improvements : null}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Legal: {props.taxes.length > 0 ? props.taxes[0].legal_description : null}
            </Grid>
            <Grid item xs={6}>
              Land Value: {props.taxes.length > 0 ? props.taxes[0].land_value : null}
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              Search Type: {props.project.search_type}
            </Grid>
            <Grid item xs={6}>
              Total Assesed Value: {props.taxes.length > 0 ? props.taxes[0].total_assessed : null}
            </Grid>
          </Grid>
        </Grid>
        <br />
        <AppBar position="static">
          <Tabs value={currentTabIndex} onChange={(event, value) => setCurrentTabIndex(value)}>
              <Tab label="Project" />
              <Tab label="Tax" />
              <Tab label="WD" />
              <Tab label="DT" />
              <Tab label="Judgment/MISC" />
              <Tab label="Stopped" />
              <Tab label="RPT" />
              <div className={classes.documentsButtonContainer}>
              </div>
              <Tooltip title="Documents">
                <Button color="inherit" onClick={() => setShowDocumentsDrawer(true)}>
                  <DescriptionIcon />
                </Button>
              </Tooltip>
          </Tabs>
        </AppBar>
        <TabPanel value={currentTabIndex} index={0}>
          <Project onContinueClicked={() => continueToIndex(1, 'project')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <Tax onContinueClicked={() => continueToIndex(2, 'taxes', 'taxes')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={2}>
          <WarrantyDeed onContinueClicked={() => continueToIndex(3, 'warranty_deeds', 'warrantyDeeds')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={3}>
          <DeedOfTrust onContinueClicked={() => continueToIndex(4, 'deed_of_trusts', 'deedOfTrusts')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={4}>
          <Judgement onContinueClicked={() => continueToIndex(5, 'judgements', 'judgements')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={5}>
          <Stopped onContinueClicked={() => continueToIndex(6, 'stops', 'stops')} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={6}>
          <ReportData onContinueClicked={() => continueToIndex(7)} />
        </TabPanel>
      </CustomCard>
      <DocumentsDrawer
        show={showDocumentsDrawer}
        onDrawerClosed={() => setShowDocumentsDrawer(false)}
        projectId={props.match.params.id} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isLoading: state.project.loading,

    projectState: state.project,

    project: state.project.project,
    warrantyDeeds: state.project.warrantyDeeds,
    taxes: state.project.taxes,
    deedOfTrusts: state.project.deedOfTrusts,
    judgements: state.project.judgements,
    stops: state.project.stops,
    runSheets: state.project.runSheets,

    pdf: state.project.pdf,
    generatePdf: state.project.generatePdf,
    merge: state.project.merge,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadProject: (token, id) => dispatch(actions.projectLoad(token, id)),
    loadGrantorGraneeOptions: (token) => dispatch(actions.projectGranteeGrantorOptionsLoad(token)),
    loadBeneficiaryOptions: (token) => dispatch(actions.projectBeneficiaryOptionsLoad(token)),
    loadTrusteeOptions: (token) => dispatch(actions.projectTrusteeOptionsLoad(token)),

    generateReport: (project, merge) => dispatch(actions.projectGenerate(project, merge)),
    clearReport: () => dispatch(actions.projectClearReport()),

    saveAllData: (project, token) => dispatch(actions.projectSaveAllData(project, token)),
    saveData: (project, data, endpoint, token) => dispatch(actions.projectSaveData(project, data, endpoint, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessProject);
