import React, { useState, useRef } from 'react';
import {
  Button,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import axios from '../../../axios-pdf';

const RunSheet = (props) => {
  const [responseMessage, setResponseMessage] = useState(null);

  const uploadsRunSheetInputRef = useRef(0);
  const uploadsChainInputRef = useRef(0);

  const onRunSheetFileChangeHandler = (event) => {
    const formData = new FormData();
    formData.append('run_sheet', uploadsRunSheetInputRef.current.files[0]);

    axios.post(`/upload/run_sheet`, formData)
      .then((response) => {
        setResponseMessage('Run Sheet template uploaded success');
      })
      .catch((error) => {
        setResponseMessage('Error while uploading Run Sheet template');
      });
  }

  const onChainFileChangeHandler = (event) => {
    const formData = new FormData();
    formData.append('chain', uploadsChainInputRef.current.files[0]);

    axios.post(`/upload/chain`, formData)
      .then((response) => {
        setResponseMessage('Chain template uploaded success');
      })
      .catch((error) => {
        setResponseMessage('Error while uploading Chain template');
      });
  }

  const onRunSheetFileUploadClickedHandler = () => {
    uploadsRunSheetInputRef.current.click();
  }

  const onChainFileUploadClickedHandler = () => {
    uploadsChainInputRef.current.click();
  }

  return (
    <>
      <input type="file" name="file" onChange={onRunSheetFileChangeHandler} ref={uploadsRunSheetInputRef} style={{ display: 'none' }} single />
      <Button variant="contained" color="primary" startIcon={<PublishIcon />} onClick={onRunSheetFileUploadClickedHandler}>Upload Run Sheet Template</Button>

      <br />
      <br />

      <input type="file" name="file" onChange={onChainFileChangeHandler} ref={uploadsChainInputRef} style={{ display: 'none' }} single />
      <Button variant="contained" color="primary" startIcon={<PublishIcon />} onClick={onChainFileUploadClickedHandler}>Upload Chain Template</Button>

      <Alert
        show={responseMessage !== null}
        title="File Uploading"
        message={responseMessage}
        onDialogClosed={() => setResponseMessage(null)} />
    </>
  );
}

export default RunSheet;
