import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import SplitButton from '../../components/SplitButton/SplitButton';
import DataTable from '../../components/DataTable/DataTable';
import ConfirmAlert from '../../components/ConfirmAlert/ConfirmAlert';
import axios from '../../axios-main';

const Clients = (props) => {
  const [ clients, setClients ] = useState([]);
  const [ confirmPayload, setConfirmPayload ] = useState(null);

  const columns = [
    {
      id: 'name',
      title: 'Name',
    },
    {
      id: 'city',
      title: 'City',
    },
    {
      id: 'address',
      title: 'Address',
    },
    {
      id: 'actions',
      title: '',
    },
  ];

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    axios.get('/clients', config)
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteClient = (id) => {
    setConfirmPayload(null);
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.delete(`/clients/${id}`, config)
      .then((response) => {
        setClients(clients.filter(el => el.id !== id));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const createClientHandler = () => {
    props.history.push('/clients/create');
  }

  const onEditClickedHandler = (id) => {
    props.history.push(`/clients/${id}`);
  }

  const onDeleteClickedHandler = (id) => {
    setConfirmPayload(id);
  }

  const data = clients.map((el) => {
    return {
      name: el.name,
      city: el.city,
      address: el.address,
      actions: (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => onEditClickedHandler(el.id)}
          >
            Edit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteClickedHandler(el.id)}
          >
            Delete
          </Button>
        </>
      ),
    }
  });

  return (
    <Layout title="Clients">
      <CustomCard>
        <Button variant="contained" color="primary" onClick={createClientHandler}>Create Client</Button>
      </CustomCard>
      <br />
      <CustomCard title="Clients">
        <DataTable
          columns={columns}
          data={data} />
      </CustomCard>

      <ConfirmAlert
        show={confirmPayload}
        payload={confirmPayload}
        title="Delete Client"
        message="Are you sure you want to delete client"
        onConfirmed={(payload) => deleteClient(payload)}
        onDiscarded={() => setConfirmPayload(null)}
        />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(Clients);
