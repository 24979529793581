import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

const InputAlert = (props) => {
  const {show} = props;

  const [payload, setPayload] = useState('');

  useEffect(() => {
    if (show) {
      setPayload('');
    }
  }, [show]);

  return (
    <Dialog
      open={props.show}
      onClose={() => props.onDiscarded(payload)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <TextField
            value={payload}
            label={props.label}
            onChange={(event) => setPayload(event.target.value)} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onConfirmed(payload)} color="primary" autoFocus>
          Ok
        </Button>
        <Button onClick={() => props.onDiscarded(payload)} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InputAlert;
