import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import dateFormat from 'dateformat';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import DNDCheckableList from '../../../components/DNDCheckableList/DNDCheckableList';
import axios from '../../../axios-main';
import axiosPdf from '../../../axios-pdf';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 900,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const priorities = [
  {
    value: 3,
    text: 'Normal',
  },
  {
    value: 2,
    text: 'High',
  },
  {
    value: 1,
    text: 'Top',
  },
];

const booleanKeys = [
  'tax_acct_b',
  'tax_paid_co',
  'tax_paid_cty',
  'tax_due_co',
  'tax_due_cty',
  'chg_deeds_b',
  'chg_mtg_b',
  'chg_assignments_b',
  'chg_ucc_b',
  'chg_judgements_b',
  'chg_other_b',
];

const EditUpdate = (props) => {
  const classes = useStyles();

  const [update, setUpdate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isNew, setIsNew] = useState(true);
  const [isCreatedSuccess, setIsCreatedSuccess] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);

  const [pdf, setPdf] = useState(null);

  const uploadsInputRef = useRef();

  const load = async () => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }

      if (props.match.params.id) {
        const update = (await axios.get(`/projects/${props.match.params.projectId}/updates/${props.match.params.id}`, config)).data;
        setUpdate({
          ...update,
        });
        setIsNew(false);
        setIsLoading(false);
      } else {
        const project = (await axios.get(`/projects/${props.match.params.projectId}`, config)).data;
        setUpdate({
          gf: project.gf,
          state: project.state,
          county: project.county,
          borrower_name: project.borrower_name,
          address: project.address,
          searched_from: project.run_sheets.length > 0 ? project.run_sheets[0].searched_to : (new Date().toISOString().split('T')[0]),
          searched_to: new Date().toISOString().split('T')[0],
          tax_acct: '',
          tax_acct_b: false,
          tax_paid_year: '',
          tax_paid_value: '',
          tax_paid_co: false,
          tax_paid_cty: false,
          tax_due_year: project.taxes.length > 0 ? project.taxes[0].due_year_1 : '',
          tax_due_value: project.taxes.length > 0 ? (Number(project.taxes[0].due_value_1) + Number(project.taxes[0].due_value_2)) : '',
          tax_due_co: false,
          tax_due_cty: false,
          chg_deeds: '',
          chg_deeds_b: false,
          chg_mtg: '',
          chg_mtg_b: false,
          chg_assignments: '',
          chg_assignments_b: false,
          chg_ucc: '',
          chg_ucc_b: false,
          chg_judgements: '',
          chg_judgements_b: false,
          chg_other: '',
          chg_other_b: false,
          //rpt_date: new Date().toISOString().split('T')[0],
          rpt_date: dateFormat(new Date(), 'yyyy-mm-dd'),
          note: '',
          documents: [],
        });
        setIsNew(true);
        setIsLoading(false);
      }
    } catch (error) {
      props.history.goBack();
    }
  };

  useEffect(() => {
    load();
  }, []);

  const onValueChangedHandler = (event, key) => {
    let value = event.target.value;
    if (booleanKeys.includes(key)) {
      value = event.target.checked;
    }

    setUpdate({
      ...update,
      [key]: value,
    });
  }

  const save = () => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }
      const data = {
        gf: update.gf,
        state: update.state,
        county: update.county,
        borrower_name: update.borrower_name,
        address: update.address,
        searched_from: update.searched_from,
        searched_to: update.searched_to,
        tax_acct: update.tax_acct,
        tax_acct_b: update.tax_acct_b > 0,
        tax_paid_year: update.tax_paid_year,
        tax_paid_value: update.tax_paid_value,
        tax_paid_co: update.tax_paid_co > 0,
        tax_paid_cty: update.tax_paid_cty > 0,
        tax_due_year: update.tax_due_year,
        tax_due_value: update.tax_due_value,
        tax_due_co: update.tax_due_co > 0,
        tax_due_cty: update.tax_due_cty > 0,
        chg_deeds: update.chg_deeds,
        chg_deeds_b: update.chg_deeds_b > 0,
        chg_mtg: update.chg_mtg,
        chg_mtg_b: update.chg_mtg_b > 0,
        chg_assignments: update.chg_assignments,
        chg_assignments_b: update.chg_assignments_b > 0,
        chg_ucc: update.chg_ucc,
        chg_ucc_b: update.chg_ucc_b > 0,
        chg_judgements: update.chg_judgements,
        chg_judgements_b: update.chg_judgements_b > 0,
        chg_other: update.chg_other,
        chg_other_b: update.chg_other_b > 0,
        rpt_date: update.rpt_date,
        note: update.note,
      }
      if (isNew) {
        axios.post(`/projects/${props.match.params.projectId}/updates`, data, config)
          .then((response) => {
            setIsNew(false);
            setUpdate({
              ...update,
              id: response.data.id,
            });
            updatedDocuments()
              .then(() => {
                resolve(response.data.id);
              })
              .catch(error => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        data['id'] = update.id;
        axios.put(`/projects/${props.match.params.projectId}/updates/${update.id}`, data, config)
          .then((response) => {
            updatedDocuments()
              .then(() => {
                resolve(response.data.id);
              })
              .catch(error => reject(error));
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  const updatedDocuments = async (updateId) => {
    try {
      const config0 = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }

      for (let i = 0; i < update.documents.length; i++) {
        const data = {
          ...update.documents[i],
          order: i,
        };
        const id = update.documents[i].id;

        await axios.put(`/projects/${props.match.params.projectId}/updates/${updateId}/documents/${id}`, data, config0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const generate = async (updateId) => {
    try {
      const config0 = {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }

      const update2 = (await axios.get(`/projects/${props.match.params.projectId}/updates/${updateId}`, config0)).data;

      const config = {
        responseType: 'blob',
      }

      const merge = true;
      const response = await axiosPdf.post('/process_update', {
        ...update2,
        searched_from: dateFormat(new Date(update2.searched_from.replaceAll('-', '/')), 'mm/dd/yyyy'),
        searched_to: dateFormat(new Date(update2.searched_to.replaceAll('-', '/')), 'mm/dd/yyyy'),
        rpt_date: dateFormat(new Date(update2.rpt_date.replaceAll('-', '/')), 'mm/dd/yyyy'),

        tax_acct: !update2.tax_acct_b ? 'NO CHANGES TO PRIOR REPORT' : update.tax_acct,
        chg_deeds: !update2.chg_deeds_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_deeds,
        chg_mtg: !update2.chg_mtg_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_mtg,
        chg_assignments: !update2.chg_assignments_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_assignments,
        chg_ucc: !update2.chg_ucc_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_ucc,
        chg_judgements: !update2.chg_judgements_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_judgements,
        chg_other: !update2.chg_other_b ? 'NO CHANGES TO PRIOR REPORT' : update.chg_other,

        merge
      }, config);
      const newPdf = new Blob([response.data], { type: 'application/pdf' });
      setPdf(response.data);
    } catch (err) {
      throw err;
    }
  }

  const onSaveClickedHandler = () => {
    setIsLoading(true);

    save()
      .then(() => {
        props.history.push(`/projects/${props.match.params.projectId}/updates`);
      })
      .catch(err => {
        console.log(err);
        setIsErrorOccurred(true);
      });
  }

  const onSaveAndGenerateClickedHandler = () => {
    setIsLoading(true);

    save()
      .then((res) => {
        generate(res)
          .then(() => {
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch(err => {
        console.log(err);
        setIsErrorOccurred(true);
      });
  }

  const onFinishCreateingProjectHandler = () => {
    props.history.push(`/projects/${props.match.params.projectId}/updates`);
  }

  const uploadDocument = async (projectId, id, file) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    const data = new FormData();
    data.append('file', file);

    try {
      await axios.post(`/projects/${projectId}/updates/${id}/documents/upload`, data, config);
    } catch (err) {
      console.log(err);
    }
  }

  const onFileChangeHandler = async (event) => {
    try {
      for (const el of Array.from(event.target.files)) {
        await uploadDocument(props.match.params.projectId, props.match.params.id, el);
      }

      await load();
    } catch (err) {
      console.log(err);
    }
  }

  const onFileUploadClickedHandler = () => {
    uploadsInputRef.current.click();
  }


  const onItemReorderedHandler = (startIndex, endIndex) => {
    const updatedDocuments = [...update.documents];
    const [removed] = updatedDocuments.splice(startIndex, 1);
    updatedDocuments.splice(endIndex, 0, removed);

    setUpdate({
      ...update,
      documents: updatedDocuments,
    });
  }

  const inReportChangedHandler = (index, value) => {
    const updatedDocuments = [...update.documents];
    updatedDocuments[index].in_report = value;

    setUpdate({
      ...update,
      documents: updatedDocuments,
    });
  }

  const years = [];
  years.push(<MenuItem key={''} value={''}>&nbsp;</MenuItem>);
  for (let i = new Date().getFullYear() + 1; i >= 1930; i--) {
    years.push(
      <MenuItem key={i} value={i}>{i}</MenuItem>
    );
  }

  if (pdf) {
    const pdfURL = URL.createObjectURL(pdf);
    return (
      <Layout title="Updates">
        <CustomCard title={update.gf}>
          <iframe style={{ width: '100%', height: 700 }} src={pdfURL} />
        </CustomCard>
      </Layout>
    );
  }

  let content = <CircularProgress color="secondary" size={100} />;
  if (!isLoading) {
    const documentList = update.documents.map((el) => {
      return {
        id: el.id,
        text: el.name,
        inReport: el.in_report,
      }
    });

    content = (
      <>
        <div className={classes.formContainer}>
          <Grid container spacing={1}>
            <Grid container item xs={12}>
              GF: {update.gf}
            </Grid>
            <Grid container item xs={12}>
              <TextField
                className={classes.formControl}
                label="Update From:"
                value={update.searched_from}
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => onValueChangedHandler(event, 'searched_from')} />
            </Grid>
            <Grid container item xs={12}>
              <TextField
                className={classes.formControl}
                label="Update To:"
                value={update.searched_to}
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => onValueChangedHandler(event, 'searched_to')} />
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="tax_acct_b"
                  control={<Switch color="primary" checked={update.tax_acct_b} onChange={(event) => onValueChangedHandler(event, 'tax_acct_b')} />}
                  label="Tax"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.tax_acct_b &&
                  <TextField
                    className={classes.formControl}
                    label="ACC#/PIN#/TMS#"
                    value={update.tax_acct}
                    onChange={(event) => onValueChangedHandler(event, 'tax_acct')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                    Paid Through Year
                  </InputLabel>
                  <Select value={update.tax_paid_year} onChange={(event) => onValueChangedHandler(event, 'tax_paid_year')}>
                    {years}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.formControl}
                  label="Paid Through"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={update.tax_paid_value}
                  onChange={(event) => onValueChangedHandler(event, 'tax_paid_value')}
                  onKeyPress={(event) => onValueChangedHandler(event, 'tax_paid_value')} />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  value="tax_paid_co"
                  control={<Switch color="primary" checked={update.tax_paid_co} onChange={(event) => onValueChangedHandler(event, 'tax_paid_co')} />}
                  label="CO"
                  labelPlacement="end" />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  value="tax_paid_cty"
                  control={<Switch color="primary" checked={update.tax_paid_cty} onChange={(event) => onValueChangedHandler(event, 'tax_paid_cty')} />}
                  label="CTY"
                  labelPlacement="end" />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                    Paid Due Year
                  </InputLabel>
                  <Select value={update.tax_due_year} onChange={(event) => onValueChangedHandler(event, 'tax_due_year')}>
                    {years}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  className={classes.formControl}
                  label="Paid Due"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={update.tax_due_value}
                  onChange={(event) => onValueChangedHandler(event, 'tax_due_value')}
                  onKeyPress={(event) => onValueChangedHandler(event, 'tax_due_value')} />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  value="tax_due_co"
                  control={<Switch color="primary" checked={update.tax_due_co} onChange={(event) => onValueChangedHandler(event, 'tax_due_co')} />}
                  label="CO"
                  labelPlacement="end" />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  value="tax_due_cty"
                  control={<Switch color="primary" checked={update.tax_due_cty} onChange={(event) => onValueChangedHandler(event, 'tax_due_cty')} />}
                  label="CTY"
                  labelPlacement="end" />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_deeds_b"
                  control={<Switch color="primary" checked={update.chg_deeds_b} onChange={(event) => onValueChangedHandler(event, 'chg_deeds_b')} />}
                  label="Deeds"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_deeds_b &&
                  <TextField
                    className={classes.formControl}
                    label="Deeds"
                    value={update.chg_deeds}
                    onChange={(event) => onValueChangedHandler(event, 'chg_deeds')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_mtg_b"
                  control={<Switch color="primary" checked={update.chg_mtg_b} onChange={(event) => onValueChangedHandler(event, 'chg_mtg_b')} />}
                  label="MTG/DT"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_mtg_b &&
                  <TextField
                    className={classes.formControl}
                    label="MTG/TD"
                    value={update.chg_mtg}
                    onChange={(event) => onValueChangedHandler(event, 'chg_mtg')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_assignments_b"
                  control={<Switch color="primary" checked={update.chg_assignments_b} onChange={(event) => onValueChangedHandler(event, 'chg_assignments_b')} />}
                  label="Assignments"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_assignments_b &&
                  <TextField
                    className={classes.formControl}
                    label="Assignments, Modifications, REL/SAT"
                    value={update.chg_assignments}
                    onChange={(event) => onValueChangedHandler(event, 'chg_assignments')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_ucc_b"
                  control={<Switch color="primary" checked={update.chg_ucc_b} onChange={(event) => onValueChangedHandler(event, 'chg_ucc_b')} />}
                  label="UCC"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_ucc_b &&
                  <TextField
                    className={classes.formControl}
                    label="UCC,LP,ETC"
                    value={update.chg_ucc}
                    onChange={(event) => onValueChangedHandler(event, 'chg_ucc')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_judgements_b"
                  control={<Switch color="primary" checked={update.chg_judgements_b} onChange={(event) => onValueChangedHandler(event, 'chg_judgements_b')} />}
                  label="Judgements"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_judgements_b &&
                  <TextField
                    className={classes.formControl}
                    label="Judgments"
                    value={update.chg_judgements}
                    onChange={(event) => onValueChangedHandler(event, 'chg_judgements')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <FormControlLabel
                  value="chg_other_b"
                  control={<Switch color="primary" checked={update.chg_other_b} onChange={(event) => onValueChangedHandler(event, 'chg_other_b')} />}
                  label="Other"
                  labelPlacement="top" />
              </Grid>
              <Grid item xs={9}>
                {update.chg_other_b &&
                  <TextField
                    className={classes.formControl}
                    label="Other"
                    value={update.chg_other}
                    onChange={(event) => onValueChangedHandler(event, 'chg_other')} />
                }
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                className={classes.formControl}
                label="Date:"
                value={update.rpt_date}
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => onValueChangedHandler(event, 'rpt_date')} />
            </Grid>
            <Grid container item xs={12}>
              <TextField
                className={classes.formControl}
                label="Note:"
                value={update.note}
                onChange={(event) => onValueChangedHandler(event, 'note')} />
            </Grid>
          </Grid>
        </div>

        <br /><br />

        <CustomCard>
          <div>
            <input type="file" name="file" onChange={onFileChangeHandler} ref={uploadsInputRef} style={{ display: 'none' }} multiple />
            <Button variant="contained" color="primary" startIcon={<PublishIcon />} onClick={onFileUploadClickedHandler}>Upload</Button>
          </div>
          <br /><br />
          <DNDCheckableList items={documentList} onItemReordered={onItemReorderedHandler} inReportChanged={inReportChangedHandler} />
        </CustomCard>

        <br /><br />

        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveClickedHandler}>Save</Button>
          &nbsp;
          <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onSaveAndGenerateClickedHandler}>Save and Generate</Button>
        </div>
      </>
    );
  }

  return (
    <Layout title="Updates">
      <CustomCard>
        {content}
      </CustomCard>

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="Update saved successfully"
        onDialogClosed={onFinishCreateingProjectHandler} />
      <Alert
        show={isErrorOccurred}
        title="Error"
        message="Error ocured while saveing update"
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(EditUpdate);
