import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import * as actions from '../../../store/actions';

const numberWithCommas = (x) => {
  if (!x || x === '') {
    return '';
  }

  return x.toLocaleString('en-US');

  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts.length > 1) {
    return parts.join(".");
  }

  return parts[0];
  //return parts[0] + '.00';
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 700,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const Tax = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.taxes.length === 0) {
      props.addTax({
        parcel_no: '',
        legal_description: '',
        land_value: '0',
        improvements: '0',
        other_value: '0',
        total_assessed: '0',
        paid_thru_year_1: '',
        paid_thru_value_1: 0,
        paid_thru_1_co: false,
        paid_thru_1_cty: false,
        paid_thru_year_2: '',
        paid_thru_value_2: 0,
        paid_thru_2_co: false,
        paid_thru_2_cty: false,
        due_year_1: '',
        due_value_1: 0,
        due_1_co: false,
        due_1_cty: false,
        due_year_2: '',
        due_value_2: 0,
        due_2_co: false,
        due_2_cty: false,
        chain: false,
        public_road: true,
        mobile_home: 0,
        note: '',
      });
    }
  }, []);

  const updateFieldHandler = (event, index, field) => {
    let value = event.target.value;

    if (field === 'paid_thru_1_co' || field === 'paid_thru_1_cty' ||
        field === 'paid_thru_2_co' || field === 'paid_thru_2_cty' ||
        field === 'due_1_co' || field === 'due_1_cty' ||
        field === 'due_2_co' || field === 'due_2_cty') {
      value = event.target.checked;
    } else if (field === 'land_value' || field === 'improvements' || field === 'other_value') {
      const landValue = field !== 'land_value' ? parseFloat(props.taxes[index].land_value.toString().replaceAll(',', '')) : parseFloat(event.target.value.replaceAll(',', ''));
      const improvements = field !== 'improvements' ? parseFloat(props.taxes[index].improvements.toString().replaceAll(',', '')) : parseFloat(event.target.value.replaceAll(',', ''));
      //const otherValue = field !== 'other_value' ? parseFloat(props.taxes[index].other_value.toString().replace(',', '')) : parseFloat(event.target.value.replace(',', ''));
      const otherValue = field !== 'other_value' ? 0 : parseFloat(event.target.value.replaceAll(',', ''));

      if (value.includes('.')) {
        const part = value.split('.');
        value = numberWithCommas(parseFloat(part[0].replaceAll(',', '')));
        value += '.' + part[1]
      } else if (value.length) {
        value = numberWithCommas(parseFloat(value.replaceAll(',', '')));
      }

      let totalAssets = 0;
      if (!isNaN(landValue)) totalAssets += landValue;
      if (!isNaN(improvements)) totalAssets += improvements;
      if (!isNaN(otherValue)) totalAssets += otherValue;

      props.updateTax(index, 'total_assessed', numberWithCommas(totalAssets));
    } else if (field == 'paid_thru_value_1' || field === 'paid_thru_value_2' || field === 'due_value_1' || field === 'due_value_2') {
      if (value.includes('.')) {
        const part = value.split('.');
        value = numberWithCommas(parseFloat(part[0].replaceAll(',', '')));
        value += '.' + part[1]
      } else if (value.length) {
        value = numberWithCommas(parseFloat(value.replaceAll(',', '')));
      }
    }

    props.updateTax(index, field, value);
  }

  const onEditFinished = (event, index, field) => {
    if (event.key === 'Enter') {
      const val = props.taxes[index][field];
      props.updateTax(index, field, numberWithCommas(val));

      event.preventDefault();
    }
  }

  const years = [];
  years.push(<MenuItem key={''} value={''}>&nbsp;</MenuItem>);
  for (let i = new Date().getFullYear() + 1; i >= 1930; i--) {
    years.push(
      <MenuItem key={i} value={i}>{i}</MenuItem>
    );
  }

  const deeds = props.taxes.map((el, index) => {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Parcel"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }}
            value={el.parcel_no}
            onChange={(event) => updateFieldHandler(event, 0, 'parcel_no')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Legal Description"
            value={el.legal_description}
            onChange={(event) => updateFieldHandler(event, 0, 'legal_description')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Land value"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={el.land_value}
            onChange={(event) => updateFieldHandler(event, index, 'land_value')}
            onKeyPress={(event) => onEditFinished(event, index, 'land_value')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Improvements"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={el.improvements}
            onChange={(event) => updateFieldHandler(event, index, 'improvements')}
            onKeyPress={(event) => onEditFinished(event, index, 'improvements')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Other"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={el.other_value}
            onChange={(event) => updateFieldHandler(event, index, 'other_value')}
            onKeyPress={(event) => onEditFinished(event, index, 'other_value')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Total Assessed"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={el.total_assessed}
            onChange={(event) => updateFieldHandler(event, index, 'total_assessed')}
            onKeyPress={(event) => onEditFinished(event, index, 'total_assessed')} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                Paid Through Year
              </InputLabel>
              <Select value={el.paid_thru_year_1} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_year_1')}>
                {years}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.formControl}
              label="Paid Through"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={el.paid_thru_value_1}
              onChange={(event) => updateFieldHandler(event, index, 'paid_thru_value_1')}
              onKeyPress={(event) => onEditFinished(event, index, 'paid_thru_value_1')} />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="paid_thru_1_co"
              control={<Switch color="primary" checked={el.paid_thru_1_co} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_1_co')} />}
              label="CO"
              labelPlacement="right" />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="paid_thru_1_cty"
              control={<Switch color="primary" checked={el.paid_thru_1_cty} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_1_cty')} />}
              label="CTY"
              labelPlacement="right" />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                Paid Through Year
              </InputLabel>
              <Select value={el.paid_thru_year_2} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_year_2')}>
                {years}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.formControl}
              label="Paid Through"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={el.paid_thru_value_2}
              onChange={(event) => updateFieldHandler(event, index, 'paid_thru_value_2')}
              onKeyPress={(event) => onEditFinished(event, index, 'paid_thru_value_2')} />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="paid_thru_2_co"
              control={<Switch color="primary" checked={el.paid_thru_2_co} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_2_co')} />}
              label="CO"
              labelPlacement="right" />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="paid_thru_2_cty"
              control={<Switch color="primary" checked={el.paid_thru_2_cty} onChange={(event) => updateFieldHandler(event, index, 'paid_thru_2_cty')} />}
              label="CTY"
              labelPlacement="right" />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                Tax Due Year
              </InputLabel>
              <Select value={el.due_year_1} onChange={(event) => updateFieldHandler(event, index, 'due_year_1')}>
                {years}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.formControl}
              label="Tax Due Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={el.due_value_1}
              onChange={(event) => updateFieldHandler(event, index, 'due_value_1')}
              onKeyPress={(event) => onEditFinished(event, index, 'due_value_1')} />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="due_1_co"
              control={<Switch color="primary" checked={el.due_1_co} onChange={(event) => updateFieldHandler(event, index, 'due_1_co')} />}
              label="CO"
              labelPlacement="right" />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="due_1_cty"
              control={<Switch color="primary" checked={el.due_1_cty} onChange={(event) => updateFieldHandler(event, index, 'due_1_cty')} />}
              label="CTY"
              labelPlacement="right" />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>
                Tax Due Year
              </InputLabel>
              <Select value={el.due_year_2} onChange={(event) => updateFieldHandler(event, index, 'due_year_2')}>
                {years}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.formControl}
              label="Tax Due Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={el.due_value_2}
              onChange={(event) => updateFieldHandler(event, index, 'due_value_2')}
              onKeyPress={(event) => onEditFinished(event, index, 'due_value_2')} />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="due_2_co"
              control={<Switch color="primary" checked={el.due_2_co} onChange={(event) => updateFieldHandler(event, index, 'due_2_co')} />}
              label="CO"
              labelPlacement="right" />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              value="due_2_cty"
              control={<Switch color="primary" checked={el.due_2_cty} onChange={(event) => updateFieldHandler(event, index, 'due_2_cty')} />}
              label="CTY"
              labelPlacement="right" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Note"
            value={el.note}
            onChange={(event) => updateFieldHandler(event, index, 'note')} />
        </Grid>
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          {deeds}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    taxes: state.project.taxes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addTax: (tax) => dispatch(actions.projectArrayAdd('taxes', tax)),
    updateTax: (index, field, value) => dispatch(actions.projectArrayUpdate('taxes', index, field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tax);
