import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import {
  Autocomplete,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import dateFormat from 'dateformat';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import Alert from '../../../components/Alert/Alert';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
  },
}));

const CreateProjectsFromFiles = (props) => {
  const classes = useStyles();

  const uploadsInputRef = useRef(0);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [customerOptions, setCustomerOptions] = useState([]);

  const [creatingStartedCount, setCreatingStartedCount] = useState(0);
  const [creatingFinishedCount, setCreatingFinishedCount] = useState(0);
  const [isTryingToCreate, setIsTryingToCreate] = useState(false);
  const [isErrorOccurred, setIsErrorOccurred] = useState(false);
  const [isCreatedSuccess, setIsCreatedSuccess] = useState(false);

  const priorities = [
    {
      value: 3,
      text: 'Normal',
    },
    {
      value: 2,
      text: 'High',
    },
    {
      value: 1,
      text: 'Top',
    },
  ];

  useEffect(() => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }
    axios.get('/users', config)
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
      });

    axios.get('/clients', config)
      .then((response) => {
        setCustomerOptions(response.data.map(el => ({ text: el.name })));
      })
      .catch((error) => {
      })
  }, []);

  useEffect(() => {
    if (creatingStartedCount > 0 && creatingStartedCount > creatingFinishedCount) {
      if (!isTryingToCreate) {
        setIsTryingToCreate(true);
      }
    } else {
      if (isTryingToCreate) {
        setIsTryingToCreate(false);
        setCreatingStartedCount(0);
        setCreatingFinishedCount(0);
        if (!isErrorOccurred) {
          setIsCreatedSuccess(true);
        }
      }
    }
  }, [creatingStartedCount, creatingFinishedCount, isTryingToCreate, isErrorOccurred]);

  const onFilesChangeHandler = (event) => {
    const arr = [];
    for (let i = 0; i < event.target.files.length; i++) {
      arr.push({
        index: i,
        name: event.target.files[i].name,
        assignedTo: props.user.name,
        priority: 3,
        customer: customerOptions[0].text,
        date_ordered: dateFormat(new Date(), 'yyyy-mm-dd'),
      });
    }
    setProjects(arr);
  }

  const onFilesUploadClickedHandler = () => {
    uploadsInputRef.current.click();
  }

  const onNameChangedHandler = (event, index) => {
    const updatedProjects = [
      ...projects
    ];
    const i = updatedProjects.findIndex(el => el.index === index);
    updatedProjects[i] = {
      ...updatedProjects[i],
      name: event.target.value,
    }
    setProjects(updatedProjects);
  }

  const onPriorityChangedHandler = (event, index) => {
    const updatedProjects = [
      ...projects
    ];
    const i = updatedProjects.findIndex(el => el.index === index);
    updatedProjects[i] = {
      ...updatedProjects[i],
      priority: event.target.value,
    }
    setProjects(updatedProjects);
  }

  const onAssigneToChangedHandler = (event, index, value) => {
    const updatedProjects = [
      ...projects
    ];
    const i = updatedProjects.findIndex(el => el.index === index);
    updatedProjects[i] = {
      ...updatedProjects[i],
      assignedTo: value,
    }
    setProjects(updatedProjects);
  }

  const onCustomerChangeHandler = (event, index, value) => {
    const updatedProjects = [
      ...projects
    ];
    const i = updatedProjects.findIndex(el => el.index === index);
    updatedProjects[i] = {
      ...updatedProjects[i],
      customer: value,
    }
    setProjects(updatedProjects);
  }

  const onDateOrderedChangeHandler = (event, index) => {
    const updatedProjects = [
      ...projects
    ];
    const i = updatedProjects.findIndex(el => el.index === index);
    updatedProjects[i] = {
      ...updatedProjects[i],
      date_ordered: event.target.value,
    }
    setProjects(updatedProjects);
  }

  const uploadFile = (projectId, fileIndex) => {
    const formData = new FormData();
    formData.append('file', uploadsInputRef.current.files[fileIndex]);

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
        'Content-Type': 'multipart/form-data',
      }
    }
    axios.post(`/projects/${projectId}/documents/upload`, formData, config)
      .then((response) => {
        console.log(response.data);
        setCreatingFinishedCount((prevState) => {
          return prevState + 1;
        });
        setProjects((prevState) => {
          return prevState.filter((el) => el.index !== fileIndex);
        });
      })
      .catch((error) => {
        console.log(error);
        setCreatingFinishedCount((prevState) => {
          return prevState + 1;
        });
        setIsErrorOccurred(true);
      })
  }

  const createProject = (project) => {
    setCreatingStartedCount((prevState) => {
      return prevState + 1;
    });
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    const data = {
      project_name: project.name,
      gf: '',
      priority: project.priority,
      assigned_to: employees.find(el => el.name === project.assignedTo).id,
      status: 1,
      state: 'TX',
      date_ordered: project.date_ordered,
      customer: project.customer,
    }
    axios.post('/projects', data, config)
      .then((response) => {
        console.log(response.data);
        uploadFile(response.data.id, project.index);
      })
      .catch((error) => {
        console.log(error);
        setCreatingFinishedCount((prevState) => {
          return prevState + 1;
        });
        setIsErrorOccurred(true);
      })
  }

  const onCreateProjectsHandler = () => {
    projects.forEach((el) => {
      createProject(el);
    });
  }

  const onFinishCreateingProjectsHandler = () => {
    setIsCreatedSuccess(false);
    props.history.push('/projects');
  }

  const removeFile = (index) => {
    const updatedProjects = projects.filter(el => el.index !== index);
    setProjects(updatedProjects);
  }

  const priorityOptions = priorities.map((el) => {
    return (
      <MenuItem key={el.value} value={el.value}>{el.text}</MenuItem>
    );
  });

  const employeeOptions = employees.map(el => {
    return el.name;
  });

  const projectRows = projects.map((el) => {
    return (
      <TableRow key={el.index}>
        <TableCell>
          <TextField style={{width: '100%'}} value={el.name} onChange={(event) => onNameChangedHandler(event, el.index)} />
        </TableCell>
        <TableCell>
          <FormControl className={classes.formControl}>
            <Select value={el.priority} onChange={(event) => onPriorityChangedHandler(event, el.index)}>
              {priorityOptions}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell>
          <Autocomplete
            id="assigned_to"
            className={classes.formControl}
            options={employeeOptions}
            value={el.assignedTo}
            onChange={(event, newValue) => onAssigneToChangedHandler(event, el.index, newValue)}
            renderInput={(params) => <TextField {...params} />}
            />
        </TableCell>
        <TableCell>
          <Autocomplete
            id="customer"
            className={classes.formControl}
            options={customerOptions.map(e => e.text)}
            value={el.customer}
            onChange={(event, newValue) => onCustomerChangeHandler(event, el.index, newValue)}
            renderInput={(params) => <TextField {...params} />}
            />
        </TableCell>
        <TableCell>
          <TextField
            style={{width: '100%'}}
            type="date"
            value={el.date_ordered}
            onChange={(event) => onDateOrderedChangeHandler(event, el.index)} />
        </TableCell>
        <TableCell>
          <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={() => removeFile(el.index)}>Remove</Button>
        </TableCell>
      </TableRow>
    );
  });

  let mainComponent = (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Project Name</TableCell>
            <TableCell>Priority</TableCell>
            <TableCell>Assigned To</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Date</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectRows}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (isTryingToCreate) {
    mainComponent = <CircularProgress color="secondary" size={100} />;
  }

  return (
    <Layout title="Projects">
      <CustomCard title="Create Projects From Files">
        <input type="file" name="file" onChange={onFilesChangeHandler} ref={uploadsInputRef} style={{ display: 'none' }} multiple />
        <Button variant="contained" color="primary" startIcon={<PublishIcon />} onClick={onFilesUploadClickedHandler}>Upload Files</Button>
        <br />
        <br />

        {mainComponent}
        <br />
        <br />

        <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={onCreateProjectsHandler}>Create Projects</Button>
      </CustomCard>

      <Alert
        show={isCreatedSuccess}
        title="Success"
        message="Projects created successfully"
        onDialogClosed={onFinishCreateingProjectsHandler} />
      <Alert
        show={isErrorOccurred && !isTryingToCreate}
        title="Error"
        message="Error ocured while creating projects"
        onDialogClosed={() => setIsErrorOccurred(false)} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(CreateProjectsFromFiles);
