import { updateObject, updateArray } from '../../utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  loading: false,
  documentsLoading: false,
  generatePdf: false,
  merge: false,
  pdf: null,

  project: {},
  warrantyDeeds: [],
  taxes: [],
  deedOfTrusts: [],
  judgements: [],
  documents: [],
  stops: [],
  runSheets: [],

  granteeGrantorOptions: [],
  beneficiaryOptions: [],
  trusteeOptions: [],
}

const loadStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
}

const loadFail = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
}

const loadSuccess = (state, action) => {
  return updateObject(state, {
    loading: action.waitAfterFinish ? true : false,
    generatePdf: action.waitAfterFinish ? true : false,
    merge: action.merge ? true : false,
    pdf: null,
    project: action.project,
    warrantyDeeds: action.project.warranty_deeds,
    taxes: action.project.taxes,
    deedOfTrusts: action.project.deed_of_trusts,
    judgements: action.project.judgements,
    documents: action.project.documents,
    stops: action.project.stops,
    runSheets: action.project.run_sheets,
  });
}

const reset = (state, action) => {
  return initialState;
}

const documentUploadStart = (state, action) => {
  return updateObject(state, {
    documentsLoading: true,
  });
}

const documentUploadFail = (state, action) => {
  return updateObject(state, {
    documentsLoading: false,
  });
}

const documentUploadSuccess = (state, action) => {
  return updateObject(state, {
    documentsLoading: false,
    documents: [
      ...state.documents,
      action.document,
    ],
  });
}

const documentDeleteStart = (state, action) => {
  return updateObject(state, {
  });
}

const documentDeleteFail = (state, action) => {
  return updateObject(state, {
  });
}

const documentDeleteSuccess = (state, action) => {
  return updateObject(state, {
    documents: state.documents.filter(el => el.id !== action.id),
  });
}

const documentReorder = (state, action) => {
  const updatedDocuments = [ ...state.documents ];
  const [removed] = updatedDocuments.splice(action.startIndex, 1);
  updatedDocuments.splice(action.endIndex, 0, removed);

  return updateObject(state, {
    documents: updatedDocuments,
  });
}

const arrayAdd = (state, action) => {
  return updateObject(state, {
    [action.array]: [
      ...state[action.array],
      action.value,
    ],
  });
}

const arrayInsert = (state, action) => {
  const newArray = [...state[action.array]];
  newArray.splice(action.index, 0, action.value);

  return updateObject(state, {
    [action.array]: newArray,
  });
}

const arrayRemove = (state, action) => {
  const newArray = [...state[action.array]];
  newArray.splice(action.index, 1);

  return updateObject(state, {
    [action.array]: newArray,
  });
}

const fieldUpdate = (state, action) => {
  return updateObject(state, {
    project: updateObject(state.project, {
      [action.field]: action.value,
    }),
  });
}

const arrayUpdate = (state, action) => {
  return updateObject(state, {
    [action.array]: updateArray(state[action.array], action.index,
      updateObject(state[action.array][action.index], {
        [action.field]: action.value,
      })
    ),
  });
}

const granteeGrantorOptionsLoad = (state, action) => {
  return updateObject(state, {
    granteeGrantorOptions: action.options,
  });
}

const granteeGrantorOptionsInsert = (state, action) => {
  return updateObject(state, {
    granteeGrantorOptions: [
      ...state.granteeGrantorOptions,
      action.option,
    ],
  });
}

const beneficiaryOptionsLoad = (state, action) => {
  return updateObject(state, {
    beneficiaryOptions: action.options,
  });
}

const beneficiaryOptionsInsert = (state, action) => {
  return updateObject(state, {
    beneficiaryOptions: [
      ...state.beneficiaryOptions,
      action.option,
    ],
  });
}

const trusteeOptionsLoad = (state, action) => {
  return updateObject(state, {
    trusteeOptions: action.options,
  });
}

const trusteeOptionsInsert = (state, action) => {
  return updateObject(state, {
    trusteeOptions: [
      ...state.trusteeOptions,
      action.option,
    ],
  });
}

const pdfGenerateSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    pdf: action.pdf,
  });
}

const pdfGenerateFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    pdf: null,
  });
}

const pdfClear = (state, action) => {
  return updateObject(state, {
    loading: false,
    pdf: null,
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROJECT_LOAD_START: return loadStart(state, action);
    case actionTypes.PROJECT_LOAD_FAIL: return loadFail(state, action);
    case actionTypes.PROJECT_LOAD_SUCCESS: return loadSuccess(state, action);
    case actionTypes.PROJECT_RESET: return reset(state, action);
    case actionTypes.PROJECT_DOCUMENT_UPLOAD_START: return documentUploadStart(state, action);
    case actionTypes.PROJECT_DOCUMENT_UPLOAD_FAIL: return documentUploadFail(state, action);
    case actionTypes.PROJECT_DOCUMENT_UPLOAD_SUCCESS: return documentUploadSuccess(state, action);
    case actionTypes.PROJECT_DOCUMENT_DELETE_START: return documentDeleteStart(state, action);
    case actionTypes.PROJECT_DOCUMENT_DELETE_FAIL: return documentDeleteFail(state, action);
    case actionTypes.PROJECT_DOCUMENT_DELETE_SUCCESS: return documentDeleteSuccess(state, action);
    case actionTypes.PROJECT_DOCUMENT_REORDER: return documentReorder(state, action);
    case actionTypes.PROJECT_FIELD_UPDATE: return fieldUpdate(state, action);
    case actionTypes.PROJECT_ARRAY_ADD: return arrayAdd(state, action);
    case actionTypes.PROJECT_ARRAY_INSERT: return arrayInsert(state, action);
    case actionTypes.PROJECT_ARRAY_REMOVE: return arrayRemove(state, action);
    case actionTypes.PROJECT_ARRAY_UPDATE: return arrayUpdate(state, action);
    case actionTypes.PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD_SUCCESS: return granteeGrantorOptionsLoad(state, action);
    case actionTypes.PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT_SUCCESS: return granteeGrantorOptionsInsert(state, action);
    case actionTypes.PROJECT_BENEFICIARY_OPTIONS_LOAD_SUCCESS: return beneficiaryOptionsLoad(state, action);
    case actionTypes.PROJECT_BENEFICIARY_OPTIONS_INSERT_SUCCESS: return beneficiaryOptionsInsert(state, action);
    case actionTypes.PROJECT_TRUSTEE_OPTIONS_LOAD_SUCCESS: return trusteeOptionsLoad(state, action);
    case actionTypes.PROJECT_TRUSTEE_OPTIONS_INSERT_SUCCESS: return trusteeOptionsInsert(state, action);
    case actionTypes.PROJECT_PDF_GENERATE_SUCCESS: return pdfGenerateSuccess(state, action);
    case actionTypes.PROJECT_PDF_GENERATE_FAIL: return pdfGenerateFail(state, action);
    case actionTypes.PROJECT_PDF_CLEAR: return pdfClear(state, action);
    default: return state;
  }
}

export default reducer;
