import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import Layout from '../../hoc/Layout/Layout';
import CustomCard from '../../hoc/Card/Card';
import DataTable from '../../components/DataTable/DataTable';
import ConfirmAlert from '../../components/ConfirmAlert/ConfirmAlert';
import InputAlert from '../../components/InputAlert/InputAlert';
import axios from '../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 300,
  },
}));

const columns = [
  {
    id: 'gf',
    title: 'Title',
  },
  {
    id: 'actions',
    title: '',
  },
];

const Updates = (props) => {
  const classes = useStyles();

  const [updates, setUpdates] = useState([]);

  const [confirmPayload, setConfirmPayload] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.get(`/projects/${props.match.params.id}/updates`, config)
      .then((response) => {
        setUpdates(response.data);
      })
      .catch((error) => {
        setUpdates([]);
      });
  }

  const deleteData = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.delete(`/projects/${props.match.params.id}/updates/${id}`, config)
      .then((response) => {
        getData();
        setConfirmPayload(null);
      })
      .catch((error) => {
        setConfirmPayload(null);
      });
  }

  const onDeleteClickedHandler = (id) => {
    setConfirmPayload(id);
  }

  const onEditClickedHandler = (id) => {
    props.history.push(`/projects/${props.match.params.id}/updates/${id}`);
  }

  const onInvoiceClickedHandler = (id) => {
    props.history.push(`/projects/${props.match.params.id}/invoices/new?update=${id}`);
  }

  const onCreateClickedHandler = () => {
    props.history.push(`/projects/${props.match.params.id}/updates/new`);
  }

  const data = updates.map((el, index) => {
    return {
      gf: el.gf + ' - update ' + (index + 1),
      actions: (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => onEditClickedHandler(el.id)}
            >
            Edit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="primary"
            startIcon={<AttachMoneyIcon />}
            onClick={() => onInvoiceClickedHandler(el.id)}
            >
            Invoice
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteClickedHandler(el.id)}
            >
            Delete
          </Button>
        </>
      ),
    }
  });

  return (
    <Layout title="Updates">
      <CustomCard>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCreateClickedHandler()}>
          CREATE
        </Button>

        <br />
        <br />

        <DataTable
          columns={columns}
          data={data} />

        <ConfirmAlert
          show={confirmPayload}
          payload={confirmPayload}
          title="Delete"
          message="Are you sure you want to delete option"
          onConfirmed={(payload) => deleteData(payload)}
          onDiscarded={() => setConfirmPayload(null)}
          />
      </CustomCard>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(Updates);
