import Rect, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import Financy from './containers/Financy/Financy';
import Projects from './containers/Projects/Projects';
import CreateProjectsFromFiles from './containers/Projects/CreateProjectsFromFiles/CreateProjectsFromFiles';
import EditProject from './containers/Projects/EditProject/EditProject';
import ProjectInvoice from './containers/Projects/ProjectInvoice/ProjectInvoice';
import ProjectInvoices from './containers/Projects/ProjectInvoices/ProjectInvoices';
import Users from './containers/Users/Users';
import EditUser from './containers/Users/EditUser/EditUser';
import Clients from './containers/Clients/Clients';
import EditClient from './containers/Clients/EditClient/EditClient';
import ProcessProject from './containers/ProcessProject/ProcessProject';
import Settings from './containers/Settings/Settings';
import Updates from './containers/Updates/Updates';
import EditUpdate from './containers/Updates/EditUpdate/EditUpdate';
import * as actions from './store/actions';

class App extends Component {
  componentDidMount() {
    this.props.onLoadUI();
    this.props.onTryLogin();
  }

  render() {
    const routes = [
      {
        path: '/login',
        component: Auth,
        exact: false,
        guard: {
          role: [ 'guest' ]
        },
      },
      {
        path: '/logout',
        component: Logout,
        exact: false,
        guard: {
          role: [ 'guest', 'user', 'admin' ]
        },
      },
      {
        path: '/dashboard',
        component: Dashboard,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/financy',
        component: Financy,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/create_from_files',
        component: CreateProjectsFromFiles,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/create',
        component: EditProject,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/:projectId/invoices/new',
        component: ProjectInvoice,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/projects/:projectId/invoices/:id',
        component: ProjectInvoice,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/projects/:id/invoices',
        component: ProjectInvoices,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/projects/:id/process',
        component: ProcessProject,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/:projectId/updates/new',
        component: EditUpdate,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/:projectId/updates/:id',
        component: EditUpdate,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/:id/updates',
        component: Updates,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects/:id',
        component: EditProject,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/projects',
        component: Projects,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/my_projects',
        component: (props) => <Projects {...props} my_projects />,
        exact: false,
        guard: {
          role: [ 'user', 'admin' ]
        },
      },
      {
        path: '/settings',
        component: Settings,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/users/create',
        component: EditUser,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/users/:id',
        component: EditUser,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/users',
        component: Users,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/clients/create',
        component: EditClient,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/clients/:id',
        component: EditClient,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },
      {
        path: '/clients',
        component: Clients,
        exact: false,
        guard: {
          role: [ 'admin' ]
        },
      },

    ];

    const routesComp = routes.filter((el) => {
      if (!this.props.isAuth && el.guard.role.includes('user')) {
        return false;
      }
      if (this.props.isAuth && !el.guard.role.includes('user') && !el.guard.role.includes('admin')) {
        return false;
      }
      return true;
    }).map((el) => {
      return (
        <Route
          key={el.path}
          path={el.path}
          component={el.component}
          exact={el.exact} />
      );
    });

    let redirectComp = <Redirect to="/login" />;
    if (this.props.isAuth) {
      redirectComp = <Redirect to="/dashboard" />;
    }

    return (
      <Switch>
        {routesComp}
        {redirectComp}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onTryLogin: () => dispatch(actions.authCheckState()),
    onLoadUI: () => dispatch(actions.uiLoad()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
