import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import dateFormat from 'dateformat';

import * as actions from '../../../store/actions';
import ConfirmAlert from '../../../components/ConfirmAlert/ConfirmAlert';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 900,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const filter = createFilterOptions();

const addYears = (dt, n) => {
  dt = new Date(dt);
  return new Date(dt.setFullYear(dt.getFullYear() + n));
}

const numberWithCommas = (x) => {
  if (!x || x === '') {
    return '';
  }

  return x.toLocaleString('en-US');
}


const DeedOfTrust = (props) => {
  const classes = useStyles();

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [removingIndex, setRemovingIndex] = useState(-1);

  useEffect(() => {
    if (props.deeds.length === 0) {
      props.addDeed({
        executed_by: props.warrantyDeeds.length > 0 ? props.warrantyDeeds[0].grantee + (props.warrantyDeeds[0].grantee_sufix ? ', ' + props.warrantyDeeds[0].grantee_sufix : '') : '',
        mers: true,
        beneficiary: '',
        trustee: '',
        amount: 0,
        dated: props.warrantyDeeds.length > 0 ? props.warrantyDeeds[0].dated : '',
        recorded: props.warrantyDeeds.length > 0 ? props.warrantyDeeds[0].dated : '',
        instrument: '',
        maturity_date: props.warrantyDeeds.length > 0 && props.warrantyDeeds[0].dated !== '' ? dateFormat(addYears(props.warrantyDeeds[0].dated, 30), 'yyyy-mm-dd') : '',
        assigned: '',
        open: false,
        open_amount: '',
        note_1: '',
        note_2: '',
        no_open_dt_found: false,
      });
    }
  }, []);

  const addDeedClickedHandler = () => {
    props.addDeed({
      executed_by: '',
      beneficiary: '',
      trustee: '',
      amount: 0,
      dated: '',
      recorded: '',
      instrument: '',
      maturity_date: '',
      assigned: '',
      open: true,
      open: false,
      open_amount: '',
      note_1: '',
      note_2: '',
      no_open_dt_found: false,
    });
  }

  const insertDeedClickedHandler = (idx) => {
    props.insertDeed(idx, {
      executed_by: props.deeds.length ? props.deeds[0].executed_by : '',
      beneficiary: '',
      trustee: '',
      amount: 0,
      dated: props.warrantyDeeds.length > idx ? props.warrantyDeeds[idx].dated : '',
      recorded: props.warrantyDeeds.length > idx ? props.warrantyDeeds[idx].recorded : '',
      instrument: '',
      maturity_date: '',
      assigned: '',
      open: true,
      open: false,
      open_amount: '',
      note_1: '',
      note_2: '',
      no_open_dt_found: false,
    });
  }

  const removeDeedClickedHandler = (index) => {
    if (props.deeds.length > 1) {
      setShowRemoveAlert(true);
      setRemovingIndex(index);
    }
  }

  const removeConfirmedHandler = (index) => {
    props.removeDeed(index, props.deeds[index].id, props.project.id, props.token);
    setShowRemoveAlert(false);
  }

  const updateFieldHandler = (event, index, field, newValue) => {
    let value = event.target.value;
    if (field === 'beneficiary' || field === 'trustee') {
      if (typeof newValue === 'string') {
        value = newValue;
      } else if (newValue && newValue.inputValue) {
        value = newValue.inputValue;
        if (field === 'beneficiary') {
          props.addBeneficiaryOption(value, props.token);
        } else if (field === 'trustee') {
          props.addTrusteeOption(value, props.token);
        }
      } else {
        value = newValue.text;
      }
    } else if (field === 'open' || field === 'mers' || field === 'no_open_dt_found') {
      value = event.target.checked;

      if (field === 'open') {
        if (value) {
          props.updateDeed(index, 'open_amount', props.deeds[index].amount);
        } else {
          props.updateDeed(index, 'open_amount', '');
        }
      }
    } else if (field === 'amount' || field === 'open_amount') {
      if (value.includes('.')) {
        const part = value.split('.');
        value = numberWithCommas(parseFloat(part[0].replaceAll(',', '')));
        value += '.' + part[1]
      } else if (value.length) {
        value = numberWithCommas(parseFloat(value.replaceAll(',', '')));
      }
    }

    props.updateDeed(index, field, value);

    if (field === 'dated') {
      props.updateDeed(index, 'recorded', event.target.value);
      props.updateDeed(index, 'maturity_date', dateFormat(addYears(event.target.value, 30), 'yyyy-mm-dd'));
    }
    if (field === 'amount' && props.deeds[0].open) {
      props.updateDeed(index, 'open_amount', event.target.value);
    }
  }

  const filterOptionsHandler = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        text: 'Add: ' + params.inputValue,
      });
    }

    return filtered;
  }

  const getOptionLabelHandler = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.text;
  }

  const deeds = props.deeds.map((el, index) => {
    return (
      <>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Executed By"
            value={el.executed_by}
            onChange={(event) => updateFieldHandler(event, index, 'executed_by')} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControlLabel
              value="mers"
              control={<Switch color="primary" checked={el.mers} onChange={(event) => updateFieldHandler(event, index, 'mers')} />}
              label="mers"
              labelPlacement="top" />
          </Grid>
          <Grid item xs={9}>
            <Autocomplete
              freeSolo
              disableClearable
              selectOnFocus
              className={classes.formControl}
              value={el.beneficiary}
              onChange={(event, newValue) => updateFieldHandler(event, index, 'beneficiary', newValue)}
              options={props.beneficiaryOptions}
              filterOptions={filterOptionsHandler}
              getOptionLabel={getOptionLabelHandler}
              renderOption={(option => option.text)}
              renderInput={(params) => (
                <TextField
                  label="Beneficiary"
                  {...params} />
              )} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            disableClearable
            selectOnFocus
            className={classes.formControl}
            value={el.trustee}
            onChange={(event, newValue) => updateFieldHandler(event, index, 'trustee', newValue)}
            options={props.trusteeOptions}
            filterOptions={filterOptionsHandler}
            getOptionLabel={getOptionLabelHandler}
            renderOption={(option => option.text)}
            renderInput={(params) => (
              <TextField
                label="Trustee"
                {...params} />
            )} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Amount"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={el.amount}
            onChange={(event) => updateFieldHandler(event, index, 'amount')} />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={3}>
            <FormControlLabel
              value="open"
              control={<Switch color="primary" checked={el.open} onChange={(event) => updateFieldHandler(event, index, 'open')} />}
              label="open"
              labelPlacement="top" />
          </Grid>
          {el.open ?
            <>
              <Grid item xs={9}>
                <TextField
                  className={classes.formControl}
                  label="Amount"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={el.open_amount}
                  onChange={(event) => updateFieldHandler(event, index, 'open_amount')} />
              </Grid>
            </> : null
          }
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Dated"
            value={el.dated}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => updateFieldHandler(event, index, 'dated')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Recorded"
            value={el.recorded}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => updateFieldHandler(event, index, 'recorded')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Instrument"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }}
            value={el.instrument}
            onChange={(event) => updateFieldHandler(event, index, 'instrument')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Maturity date"
            value={el.maturity_date}
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => updateFieldHandler(event, index, 'maturity_date')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Assigned"
            value={el.assigned}
            onChange={(event) => updateFieldHandler(event, index, 'assigned')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Note"
            value={el.note_1}
            onChange={(event) => updateFieldHandler(event, index, 'note_1')} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.formControl}
            label="Note"
            value={el.note_2}
            onChange={(event) => updateFieldHandler(event, index, 'note_2')} />
        </Grid>

        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={6}>
            <Button
              className={classes.formControl}
              variant="contained"
              color="secondary"
              onClick={() => removeDeedClickedHandler(index)}><DeleteIcon /></Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={classes.formControl}
              variant="contained"
              color="primary"
              onClick={() => insertDeedClickedHandler(index + 1)}><AddIcon /></Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControlLabel
              value="no_open_dt_found"
              control={<Switch color="primary" checked={props.deeds && props.deeds.length > 0 ? props.deeds[0].no_open_dt_found : false} onChange={(event) => updateFieldHandler(event, 0, 'no_open_dt_found')} />}
              label="NO OPEN MTG/DT FOUND"
              labelPlacement="right" />
          </Grid>
          {props.deeds ? (
            <Grid item xs={12}>
              <Button
                style={{ width: '100%' }}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => insertDeedClickedHandler(0)}></Button>
            </Grid>
          ) : null}
          {props.deeds && props.deeds.length > 0 && !props.deeds[0].no_open_dt_found && deeds}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>

      <ConfirmAlert
        show={showRemoveAlert}
        payload={removingIndex}
        title="Delete"
        message="Are you sure you want to delete Deed of Trust?"
        onDiscarded={() => setShowRemoveAlert(false)}
        onConfirmed={removeConfirmedHandler} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    deeds: state.project.deedOfTrusts,
    warrantyDeeds: state.project.warrantyDeeds,
    beneficiaryOptions: state.project.beneficiaryOptions,
    trusteeOptions: state.project.trusteeOptions,
    project: state.project.project,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addDeed: (deed) => dispatch(actions.projectArrayAdd('deedOfTrusts', deed)),
    insertDeed: (index, deed) => dispatch(actions.projectArrayInsert('deedOfTrusts', index, deed)),
    updateDeed: (index, field, value) => dispatch(actions.projectArrayUpdate('deedOfTrusts', index, field, value)),
    removeDeed: (index, id, projectId, token) => dispatch(actions.projectArrayRemove('deedOfTrusts', index, id, projectId, token)),
    addBeneficiaryOption: (option, token) => dispatch(actions.projectBeneficiaryOptionsInsert(option, token)),
    addTrusteeOption: (option, token) => dispatch(actions.projectTrusteeOptionsInsert(option, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeedOfTrust);
