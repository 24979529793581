import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import CustomCard from '../../../hoc/Card/Card.js';
import ConfirmAlert from '../../../components/ConfirmAlert/ConfirmAlert';
import axios from '../../../axios-main';
import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    //maxWidth: 900,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const filter = createFilterOptions();

const WarrantyDeed = (props) => {
  const classes = useStyles();

  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [removingIndex, setRemovingIndex] = useState(-1);

  const [granteeGrantorInvOptions, setGranteeGrantorInvOptions] = useState([]);

  useEffect(() => {
    if (props.deeds.length === 0) {
      props.addDeed({
        grantee: '',
        grantee_sufix: '',
        grantor: '',
        grantor_sufix: '',
        dated: '',
        recorded: '',
        instrument: '',
        note_1: '',
        note_2: '',
      });
    }

    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }

    axios.get('grantee_grantor_inv_options', config)
      .then((response) => {
        setGranteeGrantorInvOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const addDeedClickedHandler = () => {
    if (props.deeds.length >= 2) {
      if (!props.deeds[1].note_2 || props.deeds[1].note_2 === '') {
        props.updateDeed(1, 'note_2', 'ATTACHED SEE CHAIN OF TITLES;');
      }
    }

    props.addDeed({
      grantee: props.deeds[props.deeds.length - 1].grantor,
      grantee_sufix: props.deeds[props.deeds.length - 1].grantor_sufix,
      grantor: '',
      grantor_sufix: '',
      dated: '',
      recorded: '',
      book: '',
      page: '',
      note_1: '',
      note_2: '',
    });
  }

  const insertDeedClickedHandler = (index) => {
    if (props.deeds.length >= 2) {
      if (!props.deeds[1].note_2 || props.deeds[1].note_2 === '') {
        props.updateDeed(1, 'note_2', 'ATTACHED SEE CHAIN OF TITLE;');
      }
    }

    props.insertDeed(index, {
      grantee: index > 0 ? props.deeds[index - 1].grantor : '',
      grantee_sufix: index > 0 ? props.deeds[index - 1].grantor_sufix : '',
      grantor: '',
      grantor_sufix: '',
      dated: '',
      recorded: '',
      book: '',
      page: '',
      note_1: '',
      note_2: '',
    });
  }

  const removeDeedClickedHandler = (index) => {
    if (props.deeds.length > 1) {
      setShowRemoveAlert(true);
      setRemovingIndex(index);
    }
  }

  const removeConfirmedHandler = (index) => {
    props.removeDeed(index, props.deeds[index].id, props.project.id, props.token);
    setShowRemoveAlert(false);
  }

  const updateFieldHandler = (event, index, field, newValue) => {
    if (!event) {
      return;
    }

    let value = event.target.value;

    if (field === 'grantor_sufix' || field === 'grantee_sufix') {
      if (typeof newValue === 'string') {
        value = newValue;
      } else if (newValue && newValue.inputValue) {
        value = newValue.inputValue;
        props.addGranteeGratorOption(value, props.token);
      } else {
        value = newValue.text;
      }
    }

    if (field === 'grantor' || field === 'grantee') {
      value = newValue;
    }

    console.log(index, field, value, props.deeds[index][field]);
    props.updateDeed(index, field, value);

    if (field === 'dated') {
      props.updateDeed(index, 'recorded', value);
    }
  }

  const filterOptionsHandler = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        text: 'Add: ' + params.inputValue,
      });
    }

    return filtered;
  }

  const getOptionLabelHandler = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.text;
  }

  const deeds = props.deeds.map((el, index) => {
    return (
      <>
        <Grid container item xs={12} spacing={1}>
          <CustomCard style={{width: '100%'}}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  inputValue={el.grantee ? el.grantee : ''}
                  onInputChange={(event, val) => updateFieldHandler(event, index, 'grantee', val)}
                  options={granteeGrantorInvOptions}
                  getOptionLabel={(option) => option.text}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.formControl}
                      label="Grantee" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  selectOnFocus
                  value={el.grantee_sufix}
                  onChange={(event, newValue) => updateFieldHandler(event, index, 'grantee_sufix', newValue)}
                  options={props.granteeGrantorOptions}
                  filterOptions={filterOptionsHandler}
                  getOptionLabel={getOptionLabelHandler}
                  renderOption={option => option.text}
                  renderInput={(params) => (
                    <TextField
                      label="Grantee"
                      {...params} />
                  )} />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={8}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  inputValue={el.grantor ? el.grantor : ''}
                  onInputChange={(event, val) => updateFieldHandler(event, index, 'grantor', val)}
                  options={granteeGrantorInvOptions}
                  getOptionLabel={(option) => option.text}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.formControl}
                      label="Grantor" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  freeSolo
                  disableClearable
                  selectOnFocus
                  value={el.grantor_sufix}
                  onChange={(event, newValue) => updateFieldHandler(event, index, 'grantor_sufix', newValue)}
                  options={props.granteeGrantorOptions}
                  filterOptions={filterOptionsHandler}
                  getOptionLabel={getOptionLabelHandler}
                  renderOption={(option => option.text)}
                  renderInput={(params) => (
                    <TextField
                      label="Grantor"
                      {...params} />
                  )} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.formControl} label="Dated" value={el.dated} type="date" InputLabelProps={{shrink: true}} onChange={(event) => updateFieldHandler(event, index, 'dated')} />
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.formControl} label="Recorded" value={el.recorded} type="date" InputLabelProps={{shrink: true}} onChange={(event) => updateFieldHandler(event, index, 'recorded')} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formControl}
                label="Instrument"
                InputProps={{
                  startAdornment: <InputAdornment position="start">#</InputAdornment>,
                }}
                value={el.instrument}
                onChange={(event) => updateFieldHandler(event, index, 'instrument')} />
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.formControl} label="Note" value={el.note_1} onChange={(event) => updateFieldHandler(event, index, 'note_1')} />
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.formControl} label="Note" value={el.note_2} onChange={(event) => updateFieldHandler(event, index, 'note_2')} />
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6}>
                <Button
                  className={classes.formControl}
                  variant="contained"
                  color="secondary"
                  onClick={() => removeDeedClickedHandler(index)}><DeleteIcon /></Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.formControl}
                  variant="contained"
                  color="primary"
                  onClick={() => insertDeedClickedHandler(index + 1)}><AddIcon /></Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <br />
            </Grid>
          </CustomCard>
        </Grid>
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              style={{ width: '100%' }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => insertDeedClickedHandler(0)}></Button>
          </Grid>
          {deeds}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>

      <ConfirmAlert
        show={showRemoveAlert}
        payload={removingIndex}
        title="Delete"
        message="Are you sure you want to delete Warranty Deed?"
        onDiscarded={() => setShowRemoveAlert(false)}
        onConfirmed={removeConfirmedHandler} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    deeds: state.project.warrantyDeeds,
    granteeGrantorOptions: state.project.granteeGrantorOptions,
    project: state.project.project,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addDeed: (deed) => dispatch(actions.projectArrayAdd('warrantyDeeds', deed)),
    insertDeed: (index, deed) => dispatch(actions.projectArrayInsert('warrantyDeeds', index, deed)),
    updateDeed: (index, field, value) => dispatch(actions.projectArrayUpdate('warrantyDeeds', index, field, value)),
    removeDeed: (index, id, projectId, token) => dispatch(actions.projectArrayRemove('warrantyDeeds', index, id, projectId, token)),
    addGranteeGratorOption: (option, token) => dispatch(actions.projectGranteeGrantorOptionsInsert(option, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyDeed);
