import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import axiosPdf from '../../axios-pdf';

export const projectLoad = (token, id, waitAfterFinish = false, merge = true) => {
  return (dispatch) => {
    dispatch(projectLoadStart());
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }
    axios.get(`/projects/${id}`, config)
      .then((response) => {
        dispatch(projectLoadSuccess(response.data, waitAfterFinish, merge));
      })
      .catch((error) => {
        dispatch(projectLoadFail());
      });
  }
}

export const projectLoadStart = () => {
  return {
    type: actionTypes.PROJECT_LOAD_START,
  }
}

export const projectLoadFail = () => {
  return {
    type: actionTypes.PROJECT_LOAD_FAIL,
  }
}

export const projectLoadSuccess = (project, waitAfterFinish, merge) => {
  return {
    type: actionTypes.PROJECT_LOAD_SUCCESS,
    waitAfterFinish: waitAfterFinish,
    project: project,
    merge: merge,
  }
}

export const projectReset = () => {
  return {
    type: actionTypes.PROJECT_RESET,
  }
}

export const projectDocumentUpload = (id, file, token) => {
  return (dispatch) => {
    dispatch(projectDocumentUploadStart());
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }
    const data = new FormData();
    data.append('file', file);
    axios.post(`/projects/${id}/documents/upload`, data, config)
      .then((response) => {
        dispatch(projectDocumentUploadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(projectDocumentUploadFail());
      });
  }
}

export const projectDocumentUploadStart = () => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_UPLOAD_START,
  }
}

export const projectDocumentUploadFail = () => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_UPLOAD_FAIL,
  }
}

export const projectDocumentUploadSuccess = (newDocument) => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_UPLOAD_SUCCESS,
    document: newDocument,
  }
}

export const projectDocumentDelete = (projectId, documentId, token) => {
  return (dispatch) => {
    dispatch(projectDocumentDeleteStart());
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }
    axios.delete(`/projects/${projectId}/documents/${documentId}`, config)
      .then((response) => {
        dispatch(projectDocumentDeleteSuccess(documentId));
      })
      .catch((error) => {
        dispatch(projectDocumentDeleteFail());
      });
  }
}

export const projectDocumentDeleteStart = () => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_DELETE_START,
  }
}

export const projectDocumentDeleteFail = () => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_DELETE_FAIL,
  }
}

export const projectDocumentDeleteSuccess = (id) => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_DELETE_SUCCESS,
    id: id,
  }
}

export const projectDocumentReorder = (startIndex, endIndex) => {
  return {
    type: actionTypes.PROJECT_DOCUMENT_REORDER,
    startIndex: startIndex,
    endIndex, endIndex,
  }
}

export const projectFieldUpdate = (field, value) => {
  return {
    type: actionTypes.PROJECT_FIELD_UPDATE,
    field: field,
    value: value,
  }
}

export const projectArrayAdd = (array, value) => {
  return {
    type: actionTypes.PROJECT_ARRAY_ADD,
    array: array,
    value: value,
  }
}

export const projectArrayInsert = (array, index, value) => {
  return {
    type: actionTypes.PROJECT_ARRAY_INSERT,
    array: array,
    index: index,
    value: value,
  }
}

export const projectArrayRemove = (array, index, id, projectId, token) => {
  return (dispatch) => {
    if (id) {
      const config = {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      axios.delete(`/projects/${projectId}/warranty_deeds/${id}`, config)
        .then((response) => {
        })
        .catch((error) => {
        });
    }

    dispatch(projectArrayRemoveFinished(array, index));
  }
}

const projectArrayRemoveFinished = (array, index) => {
  return {
    type: actionTypes.PROJECT_ARRAY_REMOVE,
    array: array,
    index: index,
  }
}

export const projectArrayUpdate = (array, index, field, value) => {
  return {
    type: actionTypes.PROJECT_ARRAY_UPDATE,
    array: array,
    index: index,
    field: field,
    value: value,
  }
}

export const projectGranteeGrantorOptionsLoad = (token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    axios.get('/grantee_grantor_options', config)
      .then((response) => {
        dispatch(projectGranteeGrantorOptionsLoadSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectGranteeGrantorOptionsLoadSuccess = (options) => {
  return {
    type: actionTypes.PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD_SUCCESS,
    options: options,
  }
}

export const projectGranteeGrantorOptionsInsert = (option, token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    const data = {
      text: option,
    }
    axios.post('/grantee_grantor_options', data, config)
      .then((response) => {
        dispatch(projectGranteeGrantorOptionsInsertSuccss(data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectGranteeGrantorOptionsInsertSuccss = (option) => {
  return {
    type: actionTypes.PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT_SUCCESS,
    option: option,
  }
}

export const projectBeneficiaryOptionsLoad = (token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    axios.get('/beneficiary_options', config)
      .then((response) => {
        dispatch(projectBeneficiaryOptionsLoadSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectBeneficiaryOptionsLoadSuccess = (options) => {
  return {
    type: actionTypes.PROJECT_BENEFICIARY_OPTIONS_LOAD_SUCCESS,
    options: options,
  }
}

export const projectBeneficiaryOptionsInsert = (option, token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    const data = {
      text: option,
    }
    axios.post('/beneficiary_options', data, config)
      .then((response) => {
        dispatch(projectBeneficiaryOptionsInsertSuccess(data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectBeneficiaryOptionsInsertSuccess = (option) => {
  return {
    type: actionTypes.PROJECT_BENEFICIARY_OPTIONS_INSERT_SUCCESS,
    option: option,
  }
}

export const projectTrusteeOptionsLoad = (token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    axios.get('/trustee_options', config)
      .then((response) => {
        dispatch(projectTrusteeOptionsLoadSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectTrusteeOptionsLoadSuccess = (options) => {
  return {
    type: actionTypes.PROJECT_TRUSTEE_OPTIONS_LOAD_SUCCESS,
    options: options,
  }
}

export const projectTrusteeOptionsInsert = (option, token) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }
    const data = {
      text: option,
    }
    axios.post('/trustee_options', data, config)
      .then((response) => {
        dispatch(projectTrusteeOptionsInsertSuccss(data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const projectTrusteeOptionsInsertSuccss = (option) => {
  return {
    type: actionTypes.PROJECT_TRUSTEE_OPTIONS_INSERT_SUCCESS,
    option: option,
  }
}

const makePostRequest = (url, data, token) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  return axios.post(url, data, config);
}

const makePutRequest = (url, data, token) => {
  const config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  }

  return axios.put(url, data, config);
}

const saveOrUpdate = (url, data, token) => {
  if (data.id) {
    return makePutRequest(url + '/' + data.id, data, token);
  } else {
    return makePostRequest(url, data, token);
  }
}

const saveOrUpdateArray = async (array, url, token) => {
  let order = 0;
  for (const el of array) {
    const updated = { ...el };

    updated.order = order;
    order++;

    await saveOrUpdate(url, updated, token);
  };

  return true;
}

const saveOrUpdateDocuments = async (array, url, token) => {
  let order = 0;
  for (const el of array) {
    const updated = { ...el };

    updated.order = order;
    order++;

    await saveOrUpdate(url, updated, token);
  }

  return true;
}

const saveAllData = (project, token) => {
  return async (dispatch) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }

    try {
      const updatedProject = {
        ...project.project,
        assigned_to: project.project.assigned_to.id,
        status: 3,
      };
      await saveOrUpdate('/projects', updatedProject, token);
      await saveOrUpdateArray(project.warrantyDeeds, `/projects/${project.project.id}/warranty_deeds`, token);
      await saveOrUpdateArray(project.taxes, `/projects/${project.project.id}/taxes`, token);
      await saveOrUpdateArray(project.deedOfTrusts, `/projects/${project.project.id}/deed_of_trusts`, token);
      await saveOrUpdateArray(project.judgements, `/projects/${project.project.id}/judgements`, token);
      await saveOrUpdateArray(project.stops, `/projects/${project.project.id}/stops`, token);
      await saveOrUpdateArray(project.runSheets, `/projects/${project.project.id}/run_sheets`, token);
      await saveOrUpdateDocuments(project.documents, `/projects/${project.project.id}/documents`, token);
    } catch (error) {
      console.log(error);
    }
  }
}

export const projectSaveAllData = (project, token) => {
  return async (dispatch) => {
    dispatch(projectLoadStart());
    await saveAllData(project, token)(dispatch);
    dispatch(projectLoad(token, project.project.id));
  }
}

export const projectSaveAndGenerate = (project, token, merge) => {
  return async (dispatch) => {
    dispatch(projectLoadStart());
    await saveAllData(project, token)(dispatch);
    dispatch(projectLoad(token, project.project.id, true, merge));
  }
}

export const projectSaveData = (project, data, endpoint, token) => {
  return async (dispatch) => {
    dispatch(projectLoadStart());

    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }

    try {
      const updatedProject = {
        ...project.project,
        assigned_to: project.project.assigned_to.id,
        status: 1,
      };
      await saveOrUpdate('/projects', updatedProject, token);

      if (endpoint === 'project') {
        // Project is always saved for now
      } else if (endpoint === 'documents') {
        await saveOrUpdateDocuments(project.documents, `/projects/${project.project.id}/documents`, token);
      } else {
        await saveOrUpdateArray(project[data], `/projects/${project.project.id}/${endpoint}`, token);
      }
    } catch (error) {
      console.log(error);
    }

    dispatch(projectLoad(token, project.project.id));
  }
}

const projectPdfGenerateSuccess = (pdf) => {
  return {
    type: actionTypes.PROJECT_PDF_GENERATE_SUCCESS,
    loading: false,
    pdf: pdf,
  }
}

const projectPdfGenerateFail = () => {
  return {
    type: actionTypes.PROJECT_PDF_GENERATE_FAIL,
    loading: false,
  }
}

export const projectGenerate = (project, merge) => {
  return (dispatch) => {
    const config = {
      responseType: 'blob',
    }
    axiosPdf.post('/process', { ...project, merge }, config)
      .then((response) => {
        dispatch(projectPdfGenerateSuccess(response.data));
      })
      .catch(() => projectPdfGenerateFail());
  }
}

export const projectClearReport = () => {
  return {
    type: actionTypes.PROJECT_PDF_CLEAR,
  }
}
