/*
//export const FRONT_URL = 'https://platform.dbtsearch.com';
export const FRONT_URL = 'http://localhost:3000';
export const BASE_URL = 'http://localhost:8000';
export const IMAGE_BASE_URL = 'http://localhost:8000/storage/images';
export const DOCUMENTS_BASE_URL = 'http://localhost:8000/storage/files';
//export const PDF_API_URL = 'http://localhost:8001/api/v1/';
export const PDF_API_URL = 'https://pdf.dbtsearch.com/api/v1/';
*/

/*
export const FRONT_URL = 'https://platform.dbtsearch.com';
export const BASE_URL = 'https://api.dbtsearch.com';
export const IMAGE_BASE_URL = 'https://api.dbtsearch.com/storage/images';
export const DOCUMENTS_BASE_URL = 'https://api.dbtsearch.com/storage/files';
export const PDF_API_URL = 'https://pdf.dbtsearch.com/api/v1/';
*/

export const FRONT_URL = 'https://platform2.dbtsearch.com';
export const BASE_URL = 'https://api2.dbtsearch.com';
export const IMAGE_BASE_URL = 'https://api2.dbtsearch.com/storage/images';
export const DOCUMENTS_BASE_URL = 'https://api2.dbtsearch.com/storage/files';
export const PDF_API_URL = 'https://pdf2.dbtsearch.com/api/v1/';
export const INVOICE_API_URL = 'https://invoice.dbtsearch.com';
