export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const UI_TOGGLE_DRAWER = 'UI_TOGGLE_DRAWER';
export const UI_LOAD = 'UI_LOAD';

export const PROJECT_LOAD = 'PROJECT_LOAD';
export const PROJECT_LOAD_START = 'PROJECT_LOAD_START';
export const PROJECT_LOAD_FAIL = 'PROJECT_LOAD_FAIL';
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_RESET = 'PROJECT_RESET';
export const PROJECT_DOCUMENT_UPLOAD = 'PROJECT_DOCUMENT_UPLOAD';
export const PROJECT_DOCUMENT_UPLOAD_START = 'PROJECT_DOCUMENT_UPLOAD_START';
export const PROJECT_DOCUMENT_UPLOAD_FAIL = 'PROJECT_DOCUMENT_UPLOAD_FAIL';
export const PROJECT_DOCUMENT_UPLOAD_SUCCESS = 'PROJECT_DOCUMENT_UPLOAD_SUCCESS';
export const PROJECT_DOCUMENT_DELETE = 'PROJECT_DELETE_UPLOAD';
export const PROJECT_DOCUMENT_DELETE_START = 'PROJECT_DOCUMENT_DELETE_START';
export const PROJECT_DOCUMENT_DELETE_FAIL = 'PROJECT_DOCUMENT_DELETE_FAIL';
export const PROJECT_DOCUMENT_DELETE_SUCCESS = 'PROJECT_DOCUMENT_DELETE_SUCCESS';
export const PROJECT_DOCUMENT_REORDER = 'PROJECT_DOCUMENT_REORDER';
export const PROJECT_FIELD_UPDATE = 'PROJECT_FIELD_UPDATE';
export const PROJECT_ARRAY_ADD = 'PROJECT_ARRAY_ADD';
export const PROJECT_ARRAY_INSERT = 'PROJECT_ARRAY_INSERT';
export const PROJECT_ARRAY_REMOVE = 'PROJECT_ARRAY_REMOVE';
export const PROJECT_ARRAY_UPDATE = 'PROJECT_ARRAY_UPDATE';
export const PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD = 'PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD';
export const PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD_SUCCESS = 'PROJECT_GRANTEE_GRANTOR_OPTIONS_LOAD_SUCCESS';
export const PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT = 'PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT';
export const PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT_SUCCESS = 'PROJECT_GRANTEE_GRANTOR_OPTIONS_INSERT_SUCCESS';
export const PROJECT_BENEFICIARY_OPTIONS_LOAD = 'PROJECT_BENEFICIARY_OPTIONS_LOAD';
export const PROJECT_BENEFICIARY_OPTIONS_LOAD_SUCCESS = 'PROJECT_BENEFICIARY_OPTIONS_LOAD_SUCCESS';
export const PROJECT_BENEFICIARY_OPTIONS_INSERT = 'PROJECT_BENEFICIARY_OPTIONS_INSERT';
export const PROJECT_BENEFICIARY_OPTIONS_INSERT_SUCCESS = 'PROJECT_BENEFICIARY_OPTIONS_INSERT_SUCCESS';
export const PROJECT_TRUSTEE_OPTIONS_LOAD = 'PROJECT_TRUSTEE_OPTIONS_LOAD';
export const PROJECT_TRUSTEE_OPTIONS_LOAD_SUCCESS = 'PROJECT_TRUSTEE_OPTIONS_LOAD_SUCCESS';
export const PROJECT_TRUSTEE_OPTIONS_INSERT = 'PROJECT_TRUSTEE_OPTIONS_INSERT';
export const PROJECT_TRUSTEE_OPTIONS_INSERT_SUCCESS = 'PROJECT_TRUSTEE_OPTIONS_INSERT_SUCCESS';
export const PROJECT_PDF_GENERATE = 'PROJECT_PDF_GENERATE';
export const PROJECT_PDF_GENERATE_SUCCESS = 'PROJECT_PDF_GENERATE_SUCCESS';
export const PROJECT_PDF_GENERATE_FAIL = 'PROJECT_PDF_GENERATE_FAIL';
export const PROJECT_PDF_CLEAR = 'PROJECT_PDF_CLEAR';
