import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import * as actions from '../../../store/actions';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    minWidth: 400,
    maxWidth: 700,
  },
  formControl: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

const filter = createFilterOptions();

/*
const BECAUSE_OF = [
  'OPEN PMM',
  'DEVELOPER',
  '30 YEARS',
  '20 YEARS',
  'GOOD FIRST',
  'PMM > 2 YRS',
];
*/
const BECAUSE_OF = [
  {
    text: 'OPEN PMM',
    display_options: false,
  },
];

const isOpenPmm = (opt) => {
  if (opt.display_options) {
    return false;
  }

  return true;
}

const Stopped = (props) => {
  const classes = useStyles();

  const [becauseOf, setBecauseOf] = useState(BECAUSE_OF);

  const loadBecauseOf = () => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.get('/stopped_options', config)
      .then((response) => {
        setBecauseOf(response.data);
      })
      .catch((error) => {
        setBecauseOf([...BECAUSE_OF]);
      });
  }

  useEffect(() => {
    (async () => {
      await loadBecauseOf();
      if (props.stops.length === 0) {
        props.addStopped({
          open_pmm: true,
          because_of: BECAUSE_OF[0]?.text,
          mers: false,
          beneficiary: '',
          amount: '',
          recorded: '',
          instrument: '',
          satisfied: '',
        });
      }
    })();
  }, []);

  const updateFieldHandler = (event, index, field, newValue) => {
    let value = event.target.value;

    const isAutocomplete = field === 'beneficiary';
    const isCheckable = field === 'open_pmm' || field === 'developer' || field === 'years_30' || field === 'mers';
    const isBecauseOf = field === 'because_of';

    if (isAutocomplete) {
      if (typeof newValue === 'string') {
        value = newValue;
      } else if (newValue && newValue.inputValue) {
        value = newValue.inputValue;
        if (field === 'beneficiary') {
          props.addBeneficiaryOption(value, props.token);
        }
      } else {
        value = newValue.text;
      }
    } else if (isCheckable) {
      value = event.target.checked;
    } else if (isBecauseOf) {
      const opt = becauseOf.find(el => el.text === value);
      const openPmm = isOpenPmm(opt);

      if (openPmm) {
        props.updateStopped(index, 'mers', false);
        props.updateStopped(index, 'beneficiary', '');
        props.updateStopped(index, 'amount', '');
        props.updateStopped(index, 'recorded', '');
        props.updateStopped(index, 'instrument', '');
        props.updateStopped(index, 'satisfied', '');
      } else {
        props.updateStopped(index, 'mers', props.deeds.length > 0 ? props.deeds[0].mers : false);
        props.updateStopped(index, 'beneficiary', props.deeds.length > 0 ? props.deeds[0].beneficiary : '');
      }

      props.updateStopped(index, 'open_pmm', openPmm)
    }

    props.updateStopped(index, field, value);
  }

  const filterOptionsHandler = (options, params) => {
    const filtered = filter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        text: 'Add: ' + params.inputValue,
      });
    }

    return filtered;
  }

  const getOptionLabelHandler = (option) => {
    if (typeof option === 'string') {
      return option;
    }

    if (option.inputValue) {
      return option.inputValue;
    }

    return option.text;
  }

  const becauseOfValues = becauseOf.map((el) => {
    return (
      <MenuItem key={el.text} value={el.text}>{el.text}</MenuItem>
    );
  });

  const stops = props.stops.map((el, index) => {
    let stoppedFields = null;
    if (!el.open_pmm) {
      stoppedFields = (
        <>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={3}>
              <FormControlLabel
                value="mers"
                control={<Switch color="primary" checked={el.mers} onChange={(event) => updateFieldHandler(event, index, 'mers')} />}
                label="mers"
                labelPlacement="top" />
            </Grid>
            <Grid item xs={9}>
              <Autocomplete
                freeSolo
                disableClearable
                selectOnFocus
                className={classes.formControl}
                value={el.beneficiary}
                onChange={(event, newValue) => updateFieldHandler(event, index, 'beneficiary', newValue)}
                options={props.beneficiaryOptions}
                filterOptions={filterOptionsHandler}
                getOptionLabel={getOptionLabelHandler}
                renderOption={(option => option.text)}
                renderInput={(params) => (
                  <TextField
                    label="To"
                    {...params} />
                )} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Amount"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              value={el.amount}
              onChange={(event) => updateFieldHandler(event, index, 'amount')} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Recorded"
              value={el.recorded}
              type="date"
              InputLabelProps={{shrink: true}}
              onChange={(event) => updateFieldHandler(event, index, 'recorded')} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Instrument"
              InputProps={{
                startAdornment: <InputAdornment position="start">#</InputAdornment>,
              }}
              value={el.instrument}
              onChange={(event) => updateFieldHandler(event, index, 'instrument')} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.formControl}
              label="Satisfied"
              value={el.satisfied}
              type="date"
              InputLabelProps={{shrink: true}}
              onChange={(event) => updateFieldHandler(event, index, 'satisfied')} />
          </Grid>
        </>
      );
    }

    return (
      <>
        <Grid item xs={12}>
          <Select value={el.because_of} onChange={(event) => updateFieldHandler(event, index, 'because_of')}>
            {becauseOfValues}
          </Select>
        </Grid>
        {stoppedFields}
      </>
    );
  });

  return (
    <>
      <div className={classes.formContainer}>
        <Grid container spacing={1}>
          {stops}
        </Grid>
      </div>
      <br />
      <div className={classes.buttonContainer}>
        <Button variant="contained" color="primary" startIcon={<SkipNextIcon />} onClick={props.onContinueClicked}>Continue</Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    stops: state.project.stops,
    deeds: state.project.deedOfTrusts,
    beneficiaryOptions: state.project.beneficiaryOptions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addStopped: (stopped) => dispatch(actions.projectArrayAdd('stops', stopped)),
    updateStopped: (index, field, value) => dispatch(actions.projectArrayUpdate('stops', index, field, value)),
    addBeneficiaryOption: (option, token) => dispatch(actions.projectBeneficiaryOptionsInsert(option, token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stopped);
