import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

import CustomCard from '../../../hoc/Card/Card';
import DataTable from '../../../components/DataTable/DataTable';
import ConfirmAlert from '../../../components/ConfirmAlert/ConfirmAlert';
import InputAlert from '../../../components/InputAlert/InputAlert';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 300,
  },
}));

const endpoints = [
  {
    value: 'search_type_options',
    label: 'Search Type',
  },
  {
    value: 'grantee_grantor_options',
    label: 'Grantee/Grantor Sufix',
  },
  {
    value: 'grantee_grantor_inv_options',
    label: 'Grantee/Grantor',
  },
  {
    value: 'trustee_options',
    label: 'Trustee',
  },
  {
    value: 'beneficiary_options',
    label: 'Beneficiary',
  },
  {
    value: 'customer_options',
    label: 'Customers',
  },
  {
    value: 'stopped_options',
    label: 'Stopped',
  },
];

const columns = [
  {
    id: 'option',
    title: 'Value',
  },
  {
    id: 'actions',
    title: '',
  },
];

const OptionsAdministration = (props) => {
  const classes = useStyles();

  const [fieldName, setFieldName] = useState(endpoints[0].value);
  const [options, setOptions] = useState([]);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const [confirmPayload, setConfirmPayload] = useState(null);

  useEffect(() => {
    getData(fieldName);
  }, []);

  const getData = (endpoint) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.get(`/${endpoint}`, config)
      .then((response) => {
        setOptions(response.data);
      })
      .catch((error) => {
        setOptions([]);
      });
  }

  const addData = (value) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    const data = {
      text: value,
      display_options: false,
    }
    axios.post(`/${fieldName}`, data, config)
      .then((response) => {
        getData(fieldName);
        setShowAddDialog(false);
      })
      .catch((error) => {
        setShowAddDialog(false);
      });
  }

  const deleteData = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.delete(`/${fieldName}/${id}`, config)
      .then((response) => {
        getData(fieldName);
        setConfirmPayload(null);
      })
      .catch((error) => {
        setConfirmPayload(null);
      });
  }

  const onFieldNameChangeHandler = (event) => {
    setFieldName(event.target.value);
    getData(event.target.value);
  }

  const onDeleteClickedHandler = (id) => {
    setConfirmPayload(id);
  }

  const onCreateClickedHandler = () => {
    setShowAddDialog(true);
  }

  const fieldNames = endpoints.map((el) => {
    return (
      <MenuItem key={el.value} value={el.value}>{el.label}</MenuItem>
    );
  });

  const data = options.map((el) => {
    return {
      option: el.text,
      actions: (
        <>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteClickedHandler(el.id)}
            >
            Delete
          </Button>
        </>
      ),
    }
  });

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Field Name:</InputLabel>
        <Select value={fieldName} onChange={onFieldNameChangeHandler}>
          {fieldNames}
        </Select>
      </FormControl>

      <br />
      <br />
      <br />

      <Button
        variant="contained"
        color="primary"
        onClick={() => onCreateClickedHandler()}>
        CREATE
      </Button>

      <br />
      <br />

      <DataTable
        columns={columns}
        data={data} />

      <ConfirmAlert
        show={confirmPayload}
        payload={confirmPayload}
        title="Delete"
        message="Are you sure you want to delete option"
        onConfirmed={(payload) => deleteData(payload)}
        onDiscarded={() => setConfirmPayload(null)}
        />

      <InputAlert
        show={showAddDialog}
        title="Value"
        label="Value"
        onConfirmed={(payload) => addData(payload)}
        onDiscarded={() => setShowAddDialog(false)} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(OptionsAdministration);
