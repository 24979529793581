import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import Layout from '../../../hoc/Layout/Layout';
import CustomCard from '../../../hoc/Card/Card';
import DataTable from '../../../components/DataTable/DataTable';
import ConfirmAlert from '../../../components/ConfirmAlert/ConfirmAlert';
import InputAlert from '../../../components/InputAlert/InputAlert';
import axios from '../../../axios-main';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: 300,
  },
}));

const columns = [
  {
    id: 'number',
    title: 'Number',
  },
  {
    id: 'client',
    title: 'Client',
  },
  {
    id: 'type',
    title: 'Type',
  },
  {
    id: 'amount',
    title: 'Amount'
  },
  {
    id: 'paid',
    title: 'Paid'
  },
  {
    id: 'actions',
    title: '',
  },
];

const ProjectInvoices = (props) => {
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);
  const [clients, setClients] = useState([]);

  const [confirmPayload, setConfirmPayload] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token
        }
      }
      const response = await axios.get(`/projects/${props.match.params.id}/invoices`, config);
      setInvoices(response.data);

      const response2 = await axios.get('/clients', config);
      setClients(response2.data);
    } catch (err) {
      console.log(err);
      setInvoices([]);
      setClients([]);
    }
  }

  const deleteData = (id) => {
    const config = {
      headers: {
        Authorization: 'Bearer ' + props.token
      }
    }
    axios.delete(`/projects/${props.match.params.id}/invoices/${id}`, config)
      .then((response) => {
        getData();
        setConfirmPayload(null);
      })
      .catch((error) => {
        setConfirmPayload(null);
      });
  }

  const onDeleteClickedHandler = (id) => {
    setConfirmPayload(id);
  }

  const onEditClickedHandler = (id) => {
    props.history.push(`/projects/${props.match.params.id}/invoices/${id}`);
  }

  const onCreateClickedHandler = () => {
    props.history.push(`/projects/${props.match.params.id}/invoices/new`);
  }

  const onPaidChangedHandler = async (id, value) => {
    const invoice = invoices.find(el => el.id === id);
    if (!invoice) {
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: 'Bearer ' + props.token
        }
      }
      const data = {
        ...invoice,
        paid: value,
      }
      const result = await axios.put(`/projects/${props.match.params.id}/invoices/${invoice.id}`, data, config);
      getData();
    } catch (err) {
      console.log(err);
    }
  }

  const data = invoices.map((el, index) => {
    return {
      number: el.number,
      client: clients.find(e => e.id == el.clients_id)?.name,
      type: el.updates_id ? 'UPDATE' : 'SEARCH',
      amount: el.total,
      paid: (
        <>
          <Switch color="primary" checked={el.paid} onChange={(event) => { onPaidChangedHandler(el.id, event.target.checked) }} />
        </>
      ),
      actions: (
        <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => onEditClickedHandler(el.id)}
            >
            Edit
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={() => onDeleteClickedHandler(el.id)}
            >
            Delete
          </Button>
        </>
      ),
    }
  });

  return (
    <Layout title="Invoices">
      <CustomCard>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onCreateClickedHandler()}>
          CREATE
        </Button>

        <br />
        <br />

        <DataTable
          columns={columns}
          data={data} />

        <ConfirmAlert
          show={confirmPayload}
          payload={confirmPayload}
          title="Delete"
          message="Are you sure you want to delete option"
          onConfirmed={(payload) => deleteData(payload)}
          onDiscarded={() => setConfirmPayload(null)}
          />
      </CustomCard>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  }
}

export default connect(mapStateToProps)(ProjectInvoices);
