import React from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { Draggable } from "react-beautiful-dnd";

/*
 * TODO: Impelement checkable functionality (props isChecked)
 */

const getListItemStyle = (isDragging, draggableStyle) => {
  return {
    ...draggableStyle,

    ...(isDragging && {
      background: 'rgb(235, 235, 235)',
    })
  }
}

const DNDCheckableListItem = (props) => {
  return (
    <Draggable draggableId={props.id.toString()} index={props.index}>
      {(provided, snapshot) => (
        <ListItem
          ContainerComponent="li"
          ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getListItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
          <ListItemText>
            {props.text}
          </ListItemText>
          <ListItemSecondaryAction>
            <Checkbox
              checked={props.inReport}
              onChange={props.inReportChanged} />
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
}

export default DNDCheckableListItem;
