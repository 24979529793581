import React from 'react';
import {
  List,
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, } from "react-beautiful-dnd";

import DNDCheckableListItem from './DNDCheckableListItem/DNDCheckableListItem';

const getListStyle = isDraggingOver => {
  return {
    background: isDraggingOver ? 'lightblue' : 'white',
  }
}

const DNDCheckableList = (props) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    props.onItemReordered(result.source.index, result.destination.index);
  }

  const listItems = props.items.map((el, index) => {
    return (
      <DNDCheckableListItem
        key={el.id}
        index={index}
        id={el.id}
        text={el.text}
        inReport={el.inReport}
        inReportChanged={(event) => props.inReportChanged(index, event.target.checked)} />
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <List style={getListStyle(snapshot.isDraggingOver)}>
              {listItems}
            </List>
            {provided.placeholder}
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DNDCheckableList;
